import classes from "./SecQuestion.module.css";
import React, { useState, useEffect} from "react";
import Swal from "sweetalert2";
import ShortHair from "./ShortHair.png";
import MediumHair from "./MediumHair.png";
import LongHair from "./LongHair.png";

const SecQuestion = (props) => {
  const [selectStyle, setselectStyle] = useState(props.data);
  const hairStyles = [
    {
      id: 1,
      title: "Small",
      image: ShortHair,
      answer: 1,
      description: "Your hair ranges from a cropped cut to chin length"
    },
    {
      id: 2,
      title: "Medium",
      image: MediumHair,
      answer: 2,
      description: "Your hair falls from below the chin to the collarbone."
    },
    {
      id: 3,
      title: "Large",
      image: LongHair,
      answer: 3,
      description: "Your hair hangs from below the collarbone and downwards"
    },
  ];

  const $ = window.$
  useEffect(() => {  
    window.scrollTo(0, 0)

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  },[]);

  const handleSelectedStyle = (selectStyle) => {
    props.handleSecAnswer(selectStyle.answer);
    setselectStyle(selectStyle.id);
  };
  const handleNextButton = () => {
    if (!selectStyle) {
      Swal.fire({
        title: "Error",
        text: "Please select hair length",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      props.next();
    }
  };
  const handleBackButton = () => {
    props.back();
  };

  return (
    <div className={[`question-in-main`,classes.secondQuestionMainDiv].join(" ")}>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 progress-main">
          <div className="progress brwn-prgrs">
            <div className="progress-bar progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "33.3%"}}><span>33%</span></div>
          </div>
        </div>
      </div>

      <div className={[`question-in`, classes.questionContainer].join(" ")}>
        <h2
          className={[`text-center`, `noMarginBottom`, classes.question].join(
            " "
          )}
        >
          2. WHAT IS YOUR HAIR LENGTH?
          <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="right" title="Hair length is the overall measurement of head hair starting from the scalp to the end of the shaft. This question includes shrinkage and the length of hair in its natural state.">i</button>
        </h2>
        
        <div
          className="d-flex justify-content-center align-items-center"
        >
          <div className="row justify-content-center">
            {hairStyles.map((style, i) => {
              return (
                <div
                  className={[
                    `col-6`,
                    `col-md-4`,
                    `d-flex`,
                    `justify-content-center`,
                    `flex-column`,
                    `align-items-center`,
                    `mt-4`,
                    classes.hairStyleDiv,
                  ].join(" ")}
                  key={i}
                  onClick={() => handleSelectedStyle(style)}
                >
                  <div className={classes.styleWrapperDiv}>
                    <div className="d-flex justify-content-center">
                      {style.id === selectStyle && (
                        <div
                          className={[
                            `d-flex`,
                            `justify-content-center`,
                            `align-items-center`,
                            classes.selectOverlay,
                          ].join(" ")}
                        >
                          <i className="fas fa-check"></i>
                        </div>
                      )}
                      <img
                        alt={style.title}
                        src={style.image}
                        className={classes.hairImage}
                      />
                      <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="top" title={style.description}>i</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center q-nav-btn">
        <label
             onClick={handleBackButton}
            className={[`back-btn`, classes.nextButton].join(" ")}
          >
            BACK
          </label>
          <label
            onClick={handleNextButton}
            className={[`nxt-btn`, classes.nextButton].join(" ")}
          >
            NEXT
          </label>
      </div>
      
    </div>
  );
};

export default SecQuestion;
