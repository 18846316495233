import classes from "./FourthQuestion.module.css";
import React, { useState, useEffect} from "react";
import Swal from "sweetalert2";
import Both from "./Both.png";
import Feminine from "./Feminine.png";
import Masculine from "./Masculine.png";

const SixthQuestion = (props) => {

  const [selectStyle, setselectStyle] = useState(props.data);
  const hairStyles = [
    {
      id: 1,
      title: "Feminine",
      image: Feminine,
      answer: 1,
      description: "Having styles traditionally associated with women."
    },
    {
      id: 2,
      title: "Masculine",
      image: Masculine,
      answer: 2,
      description: "Having styles traditionally associated with men."
    },
    {
      id: 3,
      title: "Both",
      image: Both,
      answer: 3,
      description: "No preference on type of styles."
    },
  ];

  const $ = window.$
  useEffect(() => {  
    window.scrollTo(0, 0)
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  },[]);

  const handleSelectedStyle = (selectStyle) => {
    props.handleSixthAnswer(selectStyle.answer);
    setselectStyle(selectStyle.id);
  };
  const handleNextButton = () => {
    console.log(selectStyle)
    if (!selectStyle) {
      Swal.fire({
        title: "Error",
        text: "Please select style preffered",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      props.handleSubmit(selectStyle);
    }
  };

  const handleBackButton = () => {
    props.back();
  };

  return (
    <div className={[`question-in-main`,classes.firstQuestionMainDiv].join(" ")}>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 progress-main">
          <div className="progress brwn-prgrs">
            <div className="progress-bar progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}><span>100%</span></div>
          </div>
        </div>
      </div>

      <div className={[`pt-5`, `pb-4`, classes.questionContainer].join(" ")}>
        <h2
          className={[`text-center`, `noMarginBottom`, classes.question].join(
            " "
          )}
        >
          6. WHAT TYPE OF STYLES DO YOU PREFER?
          <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="right" title="For example, would you prefer to have your hair done at a barber shop or a beauty salon?">i</button>
        </h2>
       
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ transform: "translateY(-14px)" }}
        >
          <div className="col-12 col-md-11 col-lg-10 m-auto">
          <div className="row justify-content-center mt-5" >
            {hairStyles.map((style, i) => {
              return (
                <div
                  className={[
                    `col-6`,
                    `col-md-4`,
                    `d-flex`,
                    `justify-content-center`,
                    `flex-column`,
                    `align-items-center`,
                    `mt-4`,
                    classes.hairStyleDiv,
                  ].join(" ")}
                  key={i}
                  onClick={() => handleSelectedStyle(style)}
                
                >
                  <div className={classes.styleWrapperDiv}>
                    <div className="d-flex justify-content-center">
                      {style.id === selectStyle && (
                        <div
                          className={[
                            `d-flex`,
                            `justify-content-center`,
                            `align-items-center`,
                            classes.selectOverlay,
                          ].join(" ")}
                        >
                          <i className="fas fa-check"></i>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-center mb-5">
                      <label
                        className={[`pt-3`, classes.optionTitle].join(" ")}
                      >
                        {style.title}
                      </label>
                      <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="top" title={style.description}>i</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center q-nav-btn">
        <label
            onClick={handleBackButton}
            className={[`back-btn`, classes.nextButton].join(" ")}
          >
            BACK
          </label>
          <label
            onClick={handleNextButton}
            className={[`text-center`, classes.nextButton].join(" ")}
          >
            SUBMIT
          </label>
      </div>
    </div>
  );
};

export default SixthQuestion;
