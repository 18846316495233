import React from 'react'
import { Link } from 'react-router-dom'
import classes from './RecommendedButton.module.css'

function RecommendedButton(props) {
  return (
    <div className={["relative", "mb-4", classes.recommendedButton].join(" ")}>
        <Link to={props.quiz_attempted ? "/recommended-results" : "/questionaire"}>View Personalized Recommendations</Link>
    </div>
  )
}

export default RecommendedButton