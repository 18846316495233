import React, { useEffect, useState } from "react";
import aboutusCoverImage from "./headerCovers/cover11.jpg";
import classes from "./AboutUs.module.css";
import HeaderCoverNew from "../components/HeaderCoverNew";
import { Helmet } from "react-helmet";
import { useHttpClient } from "../shared/hooks/http-hook";

const Terms = (props) => {
  const {error, sendRequest } = useHttpClient();
  const [termsDescription, setTermsDescription] = useState();
  const $ = window.$;
  useEffect(() => {  
      window.scrollTo(0, 0)
      $(function () {
      $('[data-toggle="tooltip"]').tooltip()
      });
  },[]);
  useEffect(() => {
    const fetchDescription = async () => {
      
      let urltoCookiePolicy= `/flatpages/`;
      try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}${urltoCookiePolicy}`,
            "GET",
            {
              "Content-Type": "application/json",
            },
          );
          if(responseData && responseData.results && responseData.results.length){
            responseData.results.map((item) => {
              if(item.title === "Terms of use"){
                setTermsDescription(item.content);
              }
            })
          }
          else{
            setTermsDescription("Terms of use policy  Will be updated soon")
          }
          
        }
      
      catch (err) {
      }
    };
    fetchDescription();
  }, []);
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms of use</title>
      </Helmet>
      <HeaderCoverNew image={aboutusCoverImage} mainHeading="TERMS OF USE" />
      <div className="container my-5 subpage-in">
        <div className="d-flex flex-wrap">
          <div className="d-flex flex-column col-12">
            <p className={classes.para} dangerouslySetInnerHTML={{__html: termsDescription} }>
            </p>
           
          </div>
        </div>
      </div>
    </div>
  );
};
export default Terms;
