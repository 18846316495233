import React, { useState, useContext, useCallback, useReducer } from "react";
import { Link } from "react-router-dom";
import classes from "./SignUp.module.css";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/index";
import { useHistory } from "react-router-dom";
import Input from "../shared/UI/Input";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
// import { signupBg } from "../shared/images/login-bg.jpg";

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";
const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value,
    };
    const updateValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid,
    };
    let updatedFormIsValid = true;
    for (const key in updateValidities) {
      updatedFormIsValid = updatedFormIsValid && updateValidities[key];
    }
    return {
      formIsValid: updatedFormIsValid,
      inputValidities: updateValidities,
      inputValues: updatedValues,
    };
  }
  return state;
};

const SignUp = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, sendRequest } = useHttpClient();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    inputValidities: {
      firstName: false,
      lastName: false,
      email: false,
      password: false,
    },
    formIsValid: false,
  });
  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      firstName.length <= 0 ||
      lastName.length <= 0 ||
      email.length <= 0 ||
      password.length <= 0
    ) {
      Swal.fire({
        title: "Error",
        text: "Please fill the form",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } 
    else if((firstName.length > 75 ) || (lastName.length > 75 ) || (email.length > 75 ) || (password.length > 75 )){
      Swal.fire({
          title: "Error",
          text: "Enter valid details. Maximum length: 75 ",
          icon: "error",
          confirmButtonText: "Ok",
      });
    }
    else if(formState.formIsValid == false){
      Swal.fire({
        text: "Please fill valid details",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#722526",
      });
    } 
    else {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/`,
          "POST",
          {
            "Content-Type": "application/json",
          },
          JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
          })
        );
        Swal.fire({
          text: "Account created successfully. Please login",
          icon: "success",
          confirmButtonText: "OK",
        });
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        history.push("/login");
       
      } catch (err) {
        console.log(err)
        Swal.fire({
          title: "Error",
          text: "Email address already exists",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };
  const load = <LoadingSpinner />;
  return (
    <div
      className={[
        `d-flex`,
        `justify-content-center`,
        `align-items-center`,
        
        classes.loginFormMainContainer,
      ].join(" ")}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Signup</title>
      </Helmet>
      <div className="container">
      <Link to="/" className="goto-home"> &#8592; Go to home</Link>
        <div className="signup-in">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="login-left"></div>
          </div>
          <div
          className={[
            `col-12 col-md-12 col-lg-6`,
            `signup-wrap`,
            classes.loginFormContainer,
          ].join(" ")}
        >
          <div>
            <div className="">
              <h1 className={[`noMarginBottom`, `text-center`].join(" ")}>
                SIGN UP
              </h1>
              <div
                className="mb-3 mt-2"
                style={{
                  width: "50px",
                  height: "8px",
                  backgroundColor: "#8E5051",
                  margin:"auto",
                }}
              ></div>
            </div>
            <div
              className={[
                `d-flex`,
                `justify-content-center`,
                classes.formSuggestionLabelDiv,
              ].join(" ")}
            >
              <label className={[`noMarginBottom`, `text-center`,`login-link`].join(" ")}>
                <Link to="/login">
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "rgb(142, 80, 81)",
                    }}
                  >
                    Already have an account?
                  </span>
                </Link>
              </label>
            </div>
          </div>
          <div className="mt-3">
            <form onSubmit={handleSubmit}>
              <div
                className={[
                  `d-flex`,
                  `flex-column`,
                  `flex-md-row`,
                  classes.nameFieldRow,
                ].join(" ")}
              >
                <div className={[`d-flex`, `flex-column`, `mr-md-2`].join(" ")}>
                  <label className={[`flex-column`].join(" ")}>First Name*</label>
                  <Input
                    id="firstName"
                    type="text"
                    placeholder="Enter First Name"
                    onInputChange={inputChangeHandler}
                    required
                    errorText="Please enter a first name"
                    setField={setFirstName}
                  />
                </div>
                <div className={[`d-flex`, `flex-column`].join(" ")}>
                  <label className={[`flex-column`].join(" ")}>Last Name *</label>
                  <Input
                    id="lastName"
                    type="text"
                    placeholder="Enter Last Name"
                    onInputChange={inputChangeHandler}
                    required
                    errorText="Please enter a last name"
                    setField={setLastName}
                  />
                </div>
              </div>
              <div className={[`d-flex`, `flex-column`].join(" ")}>
                <label className={[`flex-column`, `mt-4`].join(" ")}>
                  Email *
                </label>
                <Input
                  id="email"
                  type="text"
                  placeholder="Enter Email"
                  onInputChange={inputChangeHandler}
                  email
                  required
                  errorText="Please enter a valid email!"
                  setField={setEmail}
                />
              </div>
              <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
                <label className={[`flex-column`].join(" ")}>Password *</label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  onInputChange={inputChangeHandler}
                  required
                  errorText="Password must have minimum one special character,numeric, uppercase, lowercase letter and  minimum password length : 6"
                  password
                  setField={setPassword}
                />
              </div>
              <div className={[`d-flex`, `flex-column`, `mt-4`,`text-center`].join(" ")}>
                {!isLoading ? (
                  <button className={[`c-btn-primary`, classes.loginButton].join(" ")} type="submit">
                    SIGN UP
                  </button>
                ) : (
                  <div className="d-flex justify-content-center">{load}</div>
                )}
              </div>
            
            </form>
          </div>
        </div>
        </div>
        </div>
        </div>
    </div>
  );
};

export default SignUp;
