import React, { useEffect, useState } from "react";
import aboutusCoverImage from "./headerCovers/cover11.jpg";
import classes from "./AboutUs.module.css";
import HeaderCoverNew from "../components/HeaderCoverNew";
import { Helmet } from "react-helmet";
import { useHttpClient } from "../shared/hooks/http-hook";

const Privacy = (props) => {
  const {error, sendRequest } = useHttpClient();
  const [privacyDescription, setPrivacyDescription] = useState();
  const $ = window.$;
  useEffect(() => {  
      window.scrollTo(0, 0)
      $(function () {
      $('[data-toggle="tooltip"]').tooltip()
      });
  },[]);

useEffect(() => {
  const fetchDescription = async () => {
    
    let urltoPrivacyPolicy= `/flatpages/`;
    try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoPrivacyPolicy}`,
          "GET",
          {
            "Content-Type": "application/json",
          },
        );
        if(responseData && responseData.results && responseData.results.length){
          responseData.results.map((item) => {
            if(item.title === "Privacy Policy"){
              setPrivacyDescription(item.content);
            }
          })
        }
        else{
          setPrivacyDescription("Privacy policy Will be updated soon")
        }
        
      }
     
    catch (err) {
    }
  };
  fetchDescription();
}, []);

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
      </Helmet>
      <HeaderCoverNew image={aboutusCoverImage} mainHeading="PRIVACY POLICY" />
      <div className="container my-5 subpage-in">
        <div className="d-flex flex-wrap">
          <div className="d-flex flex-column col-12">
            <p className={classes.para} dangerouslySetInnerHTML={{__html: privacyDescription} }>
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};
export default Privacy;
