import React from "react";
import classes from "./HeaderCoverNew.module.css";
import search from "./search.svg";

const HeaderCoverNew = (props) => {
   return (
    <div className={[`subpage-headcover-main`,classes.headerCoverMainDiv].join(" ")}>
      <div
        style={{
          backgroundImage: `-webkit-linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${props.image})`,
          backgroundImage: `linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${props.image})`,
        }}
        className={[`subpage-headcover-wrap`, classes.headerCoverDiv].join(" ")}
      >
        <div
          className={[
            `container`,
            `d-flex`,
            `flex-column`,
            `justify-content-center`,
            `align-items-center`,
            `subpage-headcover`,
            classes.headerCoverHeaderDiv,
          ].join(" ")}
        >
          <h1
            className={[`noMarginBottom`, classes.headerCoverHeading].join(" ")}
          >
            {props.mainHeading}
          </h1>
          <label
            className={[
              `noMarginBottom`,
              `mt-2`,
              `h-c-label`,
              classes.headerCoverLabel,
            ].join(" ")}
          >
            {props.subHeading}
          </label>
          <label
            className={[
              `noMarginBottom`,
              `mt-2`,
              classes.headerCoverLabel1,
            ].join(" ")}
          >
            {props.text}
          </label>
        
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default HeaderCoverNew;
