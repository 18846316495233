import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Products.module.css";
import image from "./headerCovers/Product.png";
import wishlist from "./heart.svg";
import { useAuth } from "../shared/hooks/auth-hooks";
import { useHttpClient } from "../shared/hooks/http-hook";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import { Helmet } from "react-helmet";
import Gif2 from "./new/Gif/Gif4.gif";
import Gif1 from "./new/Gif/Gif1.gif";
import { debounce, set } from 'lodash';
import Swal from "sweetalert2";
import Multiselect from 'multiselect-react-dropdown';
const HAIR_TEXTURE = [{name: "1A/1B"},
 {name:"1C"},
  {name:"2A"},
  {name:"2B"},
  {name:"2C"},
  {name:"3A"},
  {name:"3B"},
  {name:"3C"},
  {name:"4A"},
  {name:"4B"},
  {name:"4C"},
];
const HAIR_LENGTH = [
  {name:"Short"},
  {name:"Medium"},
  {name:"Long"},
];
const HAIR_DENSITY = [
  {name:"Low"},
  {name:"Medium"},
  {name:"High"},
]
const HAIR_POROSITY = [
  {name:"Low"},
  {name:"Medium"},
  {name:"High"},
];
const HAIR_PROCESSING_HISTORY = [
  {name:"Perm/Relaxer"},
  {name:"Dyed"},
  {name:"Bleached"},
  {name:"None"},
]

const HAIR_STYLE = [
  {name:"Feminine"},
  {name:"Masculine"},
  {name:"Both"},
];
const Products = (props) => {
  const { token } = useAuth();
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const {isLoading, error, sendRequest } = useHttpClient();
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const [
    loadingMoreRecommendedProducts,
    setLoadingMoreRecommendedProducts,
  ] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const [showRecommendedLoad, setShowRecommendedLoad] = useState(false);
  const [loadingRecommendedProducts, setLoadingRecommendedProducts] = useState(
    false
  );
  const [loadingAllProducts, setLoadingAllProducts] = useState(false);
  const myData = JSON.parse(localStorage.getItem("userDatas"));
  const tokenValue = JSON.parse(localStorage.getItem("userData"));

  const $ = window.$;
  const [feedBackStatus,setFeedBackStatus] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [comment, setComment] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);

  const [options, setOptions] = useState([{name: 'WHAT IS YOUR TEXTURE', id: 1},{name: 'WHAT IS YOUR HAIR LENGTH', id: 2},{name: 'WHAT IS YOUR HAIR DENSITY LEVEL', id: 3},{name: 'WHAT IS YOUR HAIR POROSITY', id: 4},{name: ' WHAT IS YOUR HISTORY OF PROCESSING', id: 5},{name: ' WHAT TYPE OF STYLES DO YOU PREFER ?', id: 6}])
  
  const [hairTextureList, setHairTextureList] = useState([]);
  const [hairLengthList, setHairLengthList] = useState([]);
  const [hairDensityList, setHairDensityList] = useState([]);
  const [hairPorosityList, setHairPorosityList] = useState([]);
  const [hairProcessingList, setHairProcessingList] = useState([]);
  const [hairStyleList, setHairStyleList] = useState([]);

  useEffect(() => {  
    window.scrollTo(0, 0)
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  },[]);

  useEffect(() => {
    const fetchProduct = async () => {
      let searchquery = searchText;
      setLoadingAllProducts(true);
      if (props.location.state) searchquery = props.location.state.search;
      let urltoEditandAdd = `/product/?is_product=True&recommended=False`;
      try {
        if(myData){
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
            "GET",
            {
              "Content-Type": "application/json",
              Authorization: "Token " + tokenValue.token,
            },
          );
          setProducts(responseData.results);
          if (responseData.results.length  >= 10) setShowLoad(true);
          setLoadingAllProducts(false);
        }
        else{
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
            "GET",
            {
              "Content-Type": "application/json",
            },
          );
          setProducts(responseData.results);
          if (responseData.results.length  >= 10) setShowLoad(true);
          setLoadingAllProducts(false);
        }
       
      } catch (err) {
        setLoadingAllProducts(true);
      }
    };
    fetchProduct();
  }, []);
  useEffect(() => {
    const fetchProduct = async () => {
      let searchquery = searchText;
      if (props.location.state) searchquery = props.location.state.search;
      setLoadingRecommendedProducts(true);
      let urltoEditandAdd = `/product/?is_product=True&recommended=True`;
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + token,

          }
        );
        if (responseData.results.length  >= 10) setShowRecommendedLoad(true);
        setRecommendedProducts(responseData.results);
        setLoadingRecommendedProducts(false);
      } catch (err) {
        setLoadingRecommendedProducts(false);
      }
    };
    if (token && myData.quiz) fetchProduct();
  }, [token, myData && myData.quiz]);
  useEffect(() => {
    const timer = debounce(async () => {
      setHairLengthList([]);
      setHairTextureList([]);
      setHairDensityList([]);
      setHairPorosityList([]);
      setHairProcessingList([]);
      setHairStyleList([]);
      try {
        setShowLoad(false);
        setLoadingAllProducts(true);
        
        if(myData){
         
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=False&search=${searchText}`,
            "GET",
            {
              "Content-Type": "application/json",
              Authorization: "Token " + tokenValue.token,

            },
          );
         
          if (responseData.results.length >= 10) setShowLoad(true);
           setLoadingAllProducts(false);
           setProducts(responseData.results);
        }
        else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=False&search=${searchText}`,
          "GET",
          {
            "Content-Type": "application/json",
          },
        );
        if (responseData.results.length >= 10) setShowLoad(true);
         setLoadingAllProducts(false);
         setProducts(responseData.results);
        }
      } catch (err) {
         setLoadingAllProducts(false);
      }
    }, 500);
    timer();
  }, [searchText]);
  useEffect(() => {
    if (!token || !myData) return;
    else if (!myData.quiz) return;
    else {
      const timer = debounce(async () => {
        try {
          let searchquery = searchText;
          if (props.location.state) searchquery = props.location.state.search;
          setLoadingRecommendedProducts(true);
         
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=True&search=${searchquery}`,
            "GET",
            {
              "Content-Type": "application/json",
              Authorization: "Token " + token,

            }
          );
          setRecommendedProducts(responseData.results);
          setLoadingRecommendedProducts(false);
    
        } catch (err) {
          setLoadingRecommendedProducts(false);
        }
      }, 500);
      timer();
    }
  }, [searchText, sendRequest, myData && myData.quiz,token]);
  const handleLoadMoreProducts = async () => {
    setLoadingMoreProducts(true);
    const hairTextureFormatted = hairTextureList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairLengthFormatted = hairLengthList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairDensityFormatted = hairDensityList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairPorosityFormatted = hairPorosityList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairProcessingFormatted = hairProcessingList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairStyleFormatted = hairStyleList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_product=True&recommended=False&search=${searchText}&limit=${products.length + 10}&hair_texture=${hairTextureFormatted.join('%')}&hair_length=${hairLengthFormatted.join('%')}&hair_density=${hairDensityFormatted.join('%')}&hair_porosity=${hairPorosityFormatted.join('%')}&hair_processing_history=${hairProcessingFormatted.join('%')}&hair_Style=${hairStyleFormatted.join('%')}`;

    try {
      if(myData){
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token,

          },
        );
       
        setProducts(responseData.results);
  
        setLoadingMoreProducts(false);
        if (responseData.results.length >= 10) {
          setShowLoad(true);
        } else {
          setShowLoad(false);
        }
      }
      else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
          },
        );
       
        setProducts(responseData.results);
  
        setLoadingMoreProducts(false);
        if (responseData.results.length >= 10) {
          setShowLoad(true);
        } else {
          setShowLoad(false);
        }
      }
    } catch (err) {
      setLoadingMoreProducts(false);
    }
  };
  const handleLoadMoreRecommendedProducts = async () => {
    setLoadingMoreRecommendedProducts(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_product=True&recommended=True&search=${searchquery}&limit=${recommendedProducts.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token
        }
      );
      setRecommendedProducts(responseData.results);
      setLoadingMoreRecommendedProducts(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoad(true);
      } else {
        setShowRecommendedLoad(false);
      }
    } catch (err) {
    }

  };

  const addToFavourite = async (val, id) => {
    setActiveIndex(id)
    let urltoWishlist = `/wishlist/`;
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let formData = new FormData();
    formData.append("id", val);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoWishlist}`,
        "POST",
        {
          Authorization: "Token " + tokenValue.token,
        },
        formData
      );
      if(responseData){
        try{
          const responseDataRecommended = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=True&search=${searchquery}`,
            "GET",
            {
              "Content-Type": "application/json",
              Authorization: "Token " + token,

            }
          );
          if (responseDataRecommended.results.length >= 10) setShowRecommendedLoad(true);

          setRecommendedProducts(responseDataRecommended.results);
    
        } catch (err) {
        }
        try {
            const responseDataAll = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=False&search=${searchText}`,
              "GET",
              {
                "Content-Type": "application/json",
                Authorization: "Token " + tokenValue.token,
              },
            );
            setProducts(responseDataAll.results);
            if (responseDataAll.results.length  >= 10) setShowLoad(true);
         
        } catch (err) {
        }
        setActiveIndex(-1)
      }
    } catch (err) {
      setActiveIndex(-1)
    }
  };
  
  
  const handleFeedback = (id, item) =>{
    setSelectedProduct(id);
    setFeedBackStatus(item)  
  };

  const handleFeedbackSubmit = async (e) =>{
    e.preventDefault();
    let urltoFeedback= "/feedback/";
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let formData = new FormData();
    formData.append("id", selectedProduct);
    if(feedBackStatus == "True"){
      formData.append("liked", "True");
    }
    else{
      formData.append("disliked", "True");
    }
    formData.append("comment", comment);
 
  
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoFeedback}`,
          "POST",
          {
            Authorization: "Token " + tokenValue.token,
          },
          formData
        );
        if(responseData){
          setSelectedProduct("");
          setFeedBackStatus("");
          setComment("");
          $("#popover-content").modal("hide");
          Swal.fire({
            title: "Success",
            text: responseData.message,
            icon: "success",
            confirmButtonText: "Ok",
        });
        try{
          const responseDataRecommended = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=True&search=${searchquery}`,
            "GET",
            {
              "Content-Type": "application/json",
              Authorization: "Token " + token,

            }
          );
          if (responseDataRecommended.results.length >= 10) setShowRecommendedLoad(true);

          setRecommendedProducts(responseDataRecommended.results);
    
        } catch (err) {
        }
        try {
            const responseDataAll = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=False&search=${searchText}`,
              "GET",
              {
                "Content-Type": "application/json",
                Authorization: "Token " + tokenValue.token,
              },
            );
            setProducts(responseDataAll.results);
            if (responseDataAll.results.length  >= 10) setShowLoad(true);
         
        } catch (err) {
        }
        }
        }
        catch{
          Swal.fire({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
        });
        }
    

  };
  const onPopUpClose =  () =>{
  setSelectedProduct("");
  setFeedBackStatus("");
  setComment("");

  };
  const onHairTextureSelect = (selectedList, selectedItem) => {
    setHairTextureList(selectedList)
  };

  const onHairLengthSelect = (selectedList, selectedItem) => {
    setHairLengthList(selectedList)
  };

  const onHairDensitySelect = (selectedList, selectedItem) => {
    setHairDensityList(selectedList)
  };

  const onHairPorositySelect = (selectedList, selectedItem) => {
    setHairPorosityList(selectedList)
  };

  const onHairProcessingSelect = (selectedList, selectedItem) => {
    setHairProcessingList(selectedList)
  };

  const onHairStyleSelect = (selectedList, selectedItem) => {
    setHairStyleList(selectedList)
  };

  const onFilterSubmit = async (e) => {
   
    const hairTextureFormatted = hairTextureList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairLengthFormatted = hairLengthList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairDensityFormatted = hairDensityList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairPorosityFormatted = hairPorosityList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairProcessingFormatted = hairProcessingList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    const hairStyleFormatted = hairStyleList.map((param) => {
      return (
        encodeURIComponent(param.name)
      )
    })
    if(hairTextureList.length == 0 &&hairStyleList.length == 0 && hairLengthList.length == 0 && hairDensityList.length == 0 && hairPorosityList.length ==0 && hairProcessingList.length == 0){

    }
    else{
      try{
        if(myData){
        const responseFilterData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=False&search=${searchText}&hair_texture=${hairTextureFormatted.join('%')}&hair_length=${hairLengthFormatted.join('%')}&hair_density=${hairDensityFormatted.join('%')}&hair_porosity=${hairPorosityFormatted.join('%')}&hair_processing_history=${hairProcessingFormatted.join('%')}&hair_Style=${hairStyleFormatted.join('%')}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token,
          },
        );
        setProducts(responseFilterData.results);
        if (responseFilterData.results.length  >= 10) {
          setShowLoad(true)
        }
        else{
          setShowLoad(false)
        }
      }
      else{
        const responseFilterData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/product/?is_product=True&recommended=False&search=${searchText}&hair_texture=${hairTextureFormatted.join('%')}&hair_length=${hairLengthFormatted.join('%')}&hair_density=${hairDensityFormatted.join('%')}&hair_porosity=${hairPorosityFormatted.join('%')}&hair_processing_history=${hairProcessingFormatted.join('%')}`,
          "GET",
          {
            "Content-Type": "application/json",
          },
        );
        setProducts(responseFilterData.results);
        if (responseFilterData.results.length  >= 10) {
          setShowLoad(true)
        }
        else{
          setShowLoad(false)
        }
      }
        } catch (err) {
        }
    }
    
  }
  const fetchProducts = async () => {
    setSearchText("")
    if(hairTextureList.length == 0 && hairLengthList.length == 0 && hairDensityList.length == 0 && hairPorosityList.length ==0 && hairProcessingList.length == 0){

    }
    else{
    setHairLengthList([]);
    setHairTextureList([]);
    setHairDensityList([]);
    setHairPorosityList([]);
    setHairProcessingList([]);
    setHairStyleList([]);
    let searchquery = searchText;
    setLoadingAllProducts(true);
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_product=True&recommended=False`;
    try {
      if(myData){
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token,
          },
        );
        setProducts(responseData.results);
        if (responseData.results.length  >= 10) setShowLoad(true);
        setLoadingAllProducts(false);
      }
      else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
          },
        );
        setProducts(responseData.results);
        if (responseData.results.length  >= 10) setShowLoad(true);
        setLoadingAllProducts(false);
      }
     
    } catch (err) {
      setLoadingAllProducts(true);
    }
  }
  }

  const load = <LoadingSpinner />;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products</title>
      </Helmet>
      <div className={[`subpage-headcover-main`,classes.headerCoverMainDiv].join(" ")}>
        <div
          style={{
            backgroundImage: `-webkit-linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
            backgroundImage: `linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
          }}
          className={[`subpage-headcover-wrap`,classes.headerCoverDiv].join(" ")}
        >
          <div
            className={[
              `container`,
              `d-flex`,
              `flex-column`,
              `justify-content-center`,
              `align-items-center`,
              `subpage-headcover`,
              classes.headerCoverHeaderDiv,
            ].join(" ")}
          >
            <h1
              className={[`noMarginBottom`, classes.headerCoverHeading].join(" ")}
            >
              THE PRODUCTS
            </h1>
            <div className="d-flex justify-content-center craft-head">
              <div
                className={[
                  `d-flex`,
                  `mt-5`,
                  `mb-5`,
                  `d-flex`,
                  `subpage-search`,
                  classes.headerCoverSearchDiv,
                ].join(" ")}
              >
                <input
                  type="text"
                  className={classes.headerCoverSearchInputField}
                  placeholder="What are you looking for?"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <img
                  className={classes.headerCoverSearchButton}
                  alt="search"
                  src="assets/img/relevant/products1/products/search.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
       
        <div>
          {token   ? (
            <div className="d-flex flex-column mt-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Products Recommended For You
              </h4>
              {loadingRecommendedProducts && (
                <div>
                  <div className="row mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theProductImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                  <img alt="GIF" src={Gif1} />
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}

              {!loadingRecommendedProducts && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5>
                      Take the Tress Hair
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      to unlock your Hair Profile.
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row mt-5">
                      {recommendedProducts.length ? (
                        recommendedProducts.map((product, i) => {
                          return (
                            <a
                              className="col-12 col-md-6 mb-3"                             
                              key={i}
                            >
                              <div className="row mb-4">
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{
                                    height: "150px",
                                  }}
                                >
                                  <div
                                    className={classes.theProductImage}
                                    style={{
                                      backgroundImage: `url(${product.image_external_url === null || product.image_external_url === "" ? product.image : product.image_external_url})`,
                                      width: "100%",
                                      height: "80%",
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                  <div className="make-wish">
                                    <a  className={product.is_wishlisted ? "active" : "" + i == activeIndex ? "active" : ""} onClick={()=> addToFavourite(product.id, i)}><img src={wishlist} className="img-fluid" alt="" /></a>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                  <a   target="_blank" rel="noopener noreferrer" href={product.link_1}>
                                  <div className="d-flex flex-column">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classes.theProductHeading,
                                      ].join(" ")}
                                    >
                                      {product.name.length > 40
                                        ? `${product.name.substr(0, 51)}...`
                                        : product.name}
                                    </h2>
                                    <div className={`pt-2`}>
                                    <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Texture :  {product.hair_texture.length > 2 ? `${product.hair_texture.join(', ').substr(0, 13)}...`
                                       : product.hair_texture.join(', ')} 
                                      </p>

                                      

                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Porosity :  {product.hair_porosity.length > 2 ? `${product.hair_porosity.join(', ').substr(0, 13)}...`
                                       : product.hair_porosity.join(', ')} 
                                      </p>

                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Processing : {product.hair_processing_history.length > 2 ? `${product.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : product.hair_processing_history.join(', ')} 
                                      </p>
                                    </div>
                                 
                                  </div>
                                  </a>
                                  {myData &&
                                  <div className="row pr-15 d-flex flex-column">
                                    <ul className="like-area like-subpage">
                                      <li className={product.is_liked ? "active" : "" }><a data-toggle="modal" data-target="#popover-content" type="button"><i className="fa fa-thumbs-up" onClick={()=>handleFeedback(product.id, "True")}></i></a></li> 
                                      <li className={product.is_disliked ? "active" : "" }><a data-toggle="modal" data-target="#popover-content" type="button"><i  className="fa fa-thumbs-down" onClick={()=>handleFeedback(product.id, "False")}></i></a></li>                                
                                    </ul>
                                </div>
                                }
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5> {searchText === "" ?   "No Products recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"}  </h5>
                        </div>
                      )}
                    </div>
                  )}
                  {loadingMoreRecommendedProducts ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      {showRecommendedLoad && (
                        <div>
                          <button
                            className={[`c-btn-primary`,`btn-explore`,classes.loadMoreButton].join(" ")}
                            onClick={handleLoadMoreRecommendedProducts}
                          >
                            Explore More 
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </div>
        {loadingAllProducts && (
          <div>
            <div className="row mb-5 mt-5">
              {[...Array(3)].map((study, i) => {
                return (
                  <span
                    className="col-12 col-md-6 mb-3"
                    target="_blank" rel="noopener noreferrer"
                    key={i}
                  >
                    <div className="row mb-4">
                      <div
                        className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                        style={{ height: "150px" }}
                      >
                        <div
                          className={classes.theProductImage}
                          style={{
                            backgroundColor: "rgb(136,136,136,0.4)",
                            width: "100%",
                            height: "100%",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "5px",
                          }}
                        ></div>
                      </div>
                      <div className="col-12 col-md-6 pt-md-0 pt-3">
                        <div className="d-flex flex-column">
                          <img alt="GIF" src={Gif2} />
                          <div className={`pt-2`}>
                            <img alt="GIF" src={Gif1} />
                            <img alt="GIF" src={Gif1} className="mt-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {!loadingAllProducts && (
          <div className="d-flex flex-column mt-5">
            <div className="mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
              Explore More Products</h4>
            </div>
            <div className="row">
              <div className="col-md-4 multiselect-drp form-group">
                <Multiselect showArrow placeholder="Texture"
                options={HAIR_TEXTURE}  // Options to display in the dropdown
                 onSelect={onHairTextureSelect} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <div className="col-md-4 multiselect-drp form-group">
                <Multiselect showArrow placeholder="Hair Length"
                options={HAIR_LENGTH}  // Options to display in the dropdown
                 onSelect={onHairLengthSelect} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <div className="col-md-4 multiselect-drp form-group">
                <Multiselect showArrow placeholder="Hair Density"
                options={HAIR_DENSITY}  // Options to display in the dropdown
                 onSelect={onHairDensitySelect} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <div className="col-md-4 multiselect-drp form-group">
                <Multiselect showArrow placeholder="Hair Porosity"
                options={HAIR_POROSITY}  // Options to display in the dropdown
                 onSelect={onHairPorositySelect} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <div className="col-md-4 multiselect-drp form-group">
                <Multiselect showArrow placeholder="History of Processing"
                options={HAIR_PROCESSING_HISTORY}  // Options to display in the dropdown
                 onSelect={onHairProcessingSelect} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <div className="col-md-4 multiselect-drp form-group">
                <Multiselect showArrow placeholder="Hair style"
                options={HAIR_STYLE}  // Options to display in the dropdown
                 onSelect={onHairStyleSelect} // Function will trigger on select event
                displayValue="name" // Property name to display in the dropdown options
                />
              </div>
              <button
                  className={[`c-btn-primary`,`btn-explore`,classes.loadMoreButton].join(" ")}
                  onClick={onFilterSubmit}
                >
                 Submit
                </button> &nbsp;
                <a  style={{
                    textDecoration: "underline",
                    color: "rgb(142, 80, 81)",
                    cursor: "pointer",
                    marginTop:"1rem",
                  }}
                  className={[`noMarginBottom`, `text-center`].join(" ")} onClick={fetchProducts}>Reset</a>
            </div>
            <div className="row mb-5 mt-5">
              {products.length ? (
                products.map((product, j) => {
                  return (
                    <a
                      className="col-12 col-md-6 mb-3"
                      
                      key={j}
                    >
                      <div className="row mb-4">
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{
                            height: "150px",
                          }}
                        >
                          <div
                            className={classes.theProductImage}
                            style={{
                              backgroundImage: `url(${product.image_external_url === null || product.image_external_url === "" ? product.image : product.image_external_url})`,
                              width: "100%",
                              height: "80%",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "5px",
                            }}
                          ></div>
                          {myData &&
                          <div className="make-wish">
                            <a  className={product.is_wishlisted ? "active" : ""  + j == activeIndex ? "active" : ""}  onClick={()=> addToFavourite(product.id, j)}><img src={wishlist} className="img-fluid" alt="" /></a>
                          </div>
                          }

                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <a target="_blank" rel="noopener noreferrer"
                             href={product.link_1}>
                          <div className="d-flex flex-column">
                            <h2
                              className={[
                                `noMarginBottom`,
                                classes.theProductHeading,
                              ].join(" ")}
                            >
                              {product.name.length > 40
                                ? `${product.name.substr(0, 51)}...`
                                : product.name}
                            </h2>
                            <div className={`pt-2`}>
                            <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Texture : {product.hair_texture.length > 2 ? `${product.hair_texture.join(', ').substr(0, 13)}...`
                                : product.hair_texture.join(', ')}
                              </p>

                              
                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Porosity : {product.hair_porosity.length > 2 ? `${product.hair_porosity.join(', ').substr(0, 13)}...`
                                : product.hair_porosity.join(', ')}
                              </p>

                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Processing : {product.hair_processing_history.length > 2 ? `${product.hair_processing_history.join(', ').substr(0, 12)}...`
                                : product.hair_processing_history.join(', ')}
                              </p>
                            </div>
                          </div>
                          </a>
                          {myData &&
                            <ul className="like-area like-subpage">
                              <li className={product.is_liked ? "active" : "" }><a data-toggle="modal" data-target="#popover-content" type="button"><i className="fa fa-thumbs-up" onClick={()=>handleFeedback(product.id, "True")}></i></a></li> 
                              <li className={product.is_disliked ? "active" : "" }><a data-toggle="modal" data-target="#popover-content" type="button"><i  className="fa fa-thumbs-down" onClick={()=>handleFeedback(product.id, "False")}></i></a></li>                                
                            </ul>
                          }
                        </div>
                      </div>
                    </a>
                  );
                })
              ) : (
                <div className="d-flex justify-content-between">
                  <h5>{searchText === "" && (hairTextureList.length == 0 && hairLengthList.length == 0 && hairDensityList.length == 0 && hairPorosityList.length == 0 && hairProcessingList.length == 0) ?   "No products" : (searchText === "" && (hairTextureList.length > 0 || hairLengthList.length > 0 || hairDensityList.length > 0 || hairPorosityList.length > 0 || hairProcessingList.length > 0)  ?  "Sorry, we couldn't find a match for your filter choices" :"Sorry, we couldn’t find a match for your search")}</h5>
                </div>
              )}
            </div>
          </div>
        )}
        {loadingMoreProducts ? (
          <LoadingSpinner />
        ) : (
          <div>
            {showLoad && (
              <div>
                <button
                  className={[`c-btn-primary`,`btn-explore`,classes.loadMoreButton].join(" ")}
                  onClick={handleLoadMoreProducts}
                >
                  Explore More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Like/Dislike Modal Start */}
      <div className="modal fade" id="popover-content" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Write your comment</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onPopUpClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                <form onSubmit={handleFeedbackSubmit} className="form-inline" role="form">
                    <div className="form-group flex-column w-100 c-poppover"> 
                      <textarea className="form-control w-100" placeholder="Write a comment" type="text" value ={comment} onChange={(e) => {setComment(e.target.value);}} maxLength="250"></textarea>
                      <div className="d-flex popover-btn mt-2">
                          <button className="btn radius-btn mr-2" id="phSearchButton"onClick={onPopUpClose}  data-dismiss="modal" >Cancel </button>
                          {!isLoading ? (
                          <button className="btn radius-btn btn-xs" id="searchButton" type="submit">Submit</button>
                          ):(
                            <div className="d-flex justify-content-center">{load}</div>
                          )
                          }
                      </div>
                    </div>
                </form>
                </div>
              </div>
          </div>
        </div>
        {/* Like/Dislike Modal end*/}
    </div>
  );
};

export default Products;
