import React, { useState } from 'react';
import GetStartedBtn from './GetStartedBtn';
import classes from './HowItWorks.module.css';

const HowItWorks = (props) => {
  const [hoverItem, setHoverItem] = useState('');
  const howWorks = [
    {
      id: 0,
      count: 1,
      image: 'assets/img/relevant/howItWorks/match.svg',
      image1: 'assets/img/relevant/howItWorks/match1.svg',
      title: 'Quiz',
      para: 'Take our 5-step quiz to learn your hair profile.',
    },
    {
      id: 1,
      count: 2,
      image: 'assets/img/relevant/howItWorks/login.svg',
      image1: 'assets/img/relevant/howItWorks/login1.svg',
      title: 'Sign-up/Login',
      para: 'See your results.',
    },
    {
      id: 3,
      count: 3,
      image: 'assets/img/relevant/howItWorks/quiz.svg',
      image1: 'assets/img/relevant/howItWorks/quiz1.svg',
      title: 'Match',
      para: 'Check out the hair care resources recommended for your hair profile!',
    },
  ];
  const handleHoverImage = (item) => {
    setHoverItem(item);
  };
  return (
    <section className="howitworks-main">
      <div
        className={[
          `container`,
          `pt-5`,
          `pb-5`,
          `howitworks-in`,
          classes.howItWordksMainDiv,
        ].join(' ')}>
        <h2
          className={[
            `text-center`,
            'section-header',
            classes.mainHeading,
          ].join(' ')}>
          How does it work?
        </h2>
        <div className="row">
          {howWorks.map((item, i) => {
            return (
              <div
                key={i}
                className={[
                  `col-12`,
                  `mb-4`,
                  `col-md-4`,
                  `d-flex`,
                  `flex-column`,
                  `justify-content-center`,
                  `align-items-center`,
                  `howitworks-each-main`,
                  classes.howWorksDiv,
                ].join(' ')}>
                <div className="howitworks-each">
                  <h6
                    className={[
                      `noMarginBottom`,
                      `step-count`,
                      classes.countLabel,
                    ].join(' ')}>
                    Step {item.count}
                  </h6>
                  <div
                    className={classes.itemImageDiv}
                    // onMouseEnter={() => handleHoverImage(item.id)}
                    // onMouseLeave={() => setHoverItem("")}
                  >
                    <img
                      className="pt-4"
                      alt={item.title}
                      src={item.id === hoverItem ? item.image1 : item.image}
                    />
                  </div>
                  <h6
                    className={[
                      `noMarginBottom`,
                      `pt-4`,
                      `pb-4`,
                      classes.howItWorksTitle,
                    ].join(' ')}>
                    {item.title}
                  </h6>
                  <p
                    className={[
                      `noMarginBottom`,
                      `text-center`,
                      classes.howItWorksPara,
                    ].join(' ')}>
                    {item.para}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <GetStartedBtn tokenValue={props.tokenValue} />
      </div>
    </section>
  );
};

export default HowItWorks;
