import classes from "./FirstQuestion.module.css";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Img1 from "./1A1B.png";
import Img2 from "./1C.png";
import Img3 from "./2A.png";
import Img4 from "./2B.png";
import Img5 from "./2C.png";
import Img6 from "./3A.png";
import Img7 from "./3B.png";
import Img8 from "./3C.png";
import Img9 from "./4A.png";
import Img10 from "./4B.png";
import Img11 from "./4C.png";
const $ = window.$

const FirstQuestion = (props) => {
  const hairStyles = [
    {
      id: 1,
      title: "Straight",
      image: Img1,
      answer: 1,
      label: "1A/1B",
      description: "Your gorgeous hair is straight, soft, and shiny. It may not hold curls well, but it retains moisture extremely well, allowing your natural oils to spread from your scalp to the ends of each hair strand."
    },
    {
      id: 2,
      title: "Straight with Body",
      image: Img2,
      answer: 2,
      label: "1C",
      description: "Your gorgeous hair is straight, but has voluminous body! It may not hold curls well, but it is one of the most resilient hair patterns. This is because your hair stays moisturized by natural oils spreading from your scalp to the ends of each hair strand."
    },
    {
      id: 3,
      title: "Wavy Swavy",
      image: Img3,
      answer: 3,
      label: "2A",
      description: "Your hair pattern is a lovely loose wave. These waves maintain your hair’s voluminous shape."
    },
    {
      id: 4,
      title: "Wavy Curvy",
      image: Img4,
      answer: 4,
      label: "2B",
      description: "Your hair pattern embodies a lovely loose S-Shape or the dreamy beach waves. Despite its beauty, you may battle frizz more often than not."
    },
    {
      id: 5,
      title: "Wavy Whirly",
      image: Img5,
      answer: 5,
      label: "2C",
      description: "Your hair pattern embodies a lovely deep S-shape curl. Despite its beauty, you may battle frizz more often than not."
    },
    {
      id: 6,
      title: "Curly Twirly",
      image: Img6,
      answer: 6,
      label: "3A",
      description: "Your hair pattern is a beautiful loose spiral curl. Your spirals are likely large enough to fit around your finger."
    },
    {
      id: 7,
      title: "Curly Spirally",
      image: Img7,
      answer: 7,
      label: "3B",
      description: "Your hair pattern is a beautiful spring-shaped curl. Your curls can likely wrap tightly around a pencil."
    },
    {
      id: 8,
      title: "Curly Kinky",
      image: Img8,
      answer: 8,
      label: "3C",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a corkscrew or zigzag shape."
    },
    {
      id: 9,
      title: "Kinky Coily",
      image: Img9,
      answer: 9,
      label: "4A",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a coily corkscrew or zigzag that can wrap around a slim toothpick."
    },
    {
      id: 10,
      title: "Kinky Crimpy",
      image: Img10,
      answer: 10,
      label: "4B",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a zigzag shape."
    },
    {
      id: 11,
      title: "Kinky Ziggly",
      image: Img11,
      answer: 11,
      label: "4C",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a zigzag shape. Your curls may be wound so tightly around themselves that you have to stretch them in order to see the shape."
    },
  ];
  
  useEffect(() => {  
    window.scrollTo(0, 0)

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  },[]);

  const [selectStyle, setselectStyle] = useState(props.data);

  const handleSelectedStyle = (selectStyle) => {
    props.handleFirstAnswer(selectStyle.answer);
    setselectStyle(selectStyle.id);
  };
  const handleNextButton = () => {
    if (!selectStyle) {
      Swal.fire({
        title: "Error",
        text: "Please select texture",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      props.next();
    }
  };
  return (
    <div className={[`question-in-main`,classes.firstQuestionMainDiv].join(" ")}>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 progress-main">
          <div className="progress brwn-prgrs">
            <div className="progress-bar  progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "16.3%"}}><span>16%</span></div>
          </div>
        </div>
      </div>

      <div className={[`question-in`, classes.questionContainer].join(" ")}>
        <h2
          className={[`text-center`, `noMarginBottom`, classes.question].join(
            " "
          )}
        >
          1. WHAT IS YOUR TEXTURE?
          <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="right" title="Hair texture is determined by the shape of the hair follicle on your scalp. Typically described as four main groups (1, 2, 3 & 4). Each group has three subcategories (a, b, & c). As the numbers increase and the letters progress, hair texture becomes more kinky and coily; the diameter of the curls become smaller.">i</button>
        </h2>
       
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ transform: "translateY(-14px)" }}
        >
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ transform: "translateY(-14px)" }}
        >
          <div className="row" style={{ flex: 1 }}>
            {hairStyles.map((style, i) => {
              return (
                // <div className="col-6 col-md-3 d-flex justify-content-center">
                //   <input type="radio" value={i} name={props.name+[i]}></input>
                //   <label for={props.name+[i]}></label>
                // </div>
                <div
                  className={[
                    `col-6`,
                    `col-md-3`,
                    `d-flex`,
                    `justify-content-center`,
                    `flex-column`,
                    `align-items-center`,
                    classes.hairStyleDiv,
                  ].join(" ")}
                  key={i}
                  // value={i}
                  // name={props.name}
                  // onClick={(event) => props.onClick(event)}
                  onClick={() => handleSelectedStyle(style)}
                >
                  <div className={classes.styleWrapperDiv}>
                    <div className="d-flex justify-content-center">
                      {style.id === selectStyle && (
                        <div className={["d-flex", "justify-content-center","align-items-center",classes.selectOverlay].join(" ")}>
                          <i className="fas fa-check"></i>
                        </div>
                      )}
                      <img
                        alt={style.title}
                        src={style.image}
                        className={classes.image}
                      />
                    </div>
                  
                    <div className="d-flex justify-content-center">
                      <label
                        className={[`pt-3`, classes.optionTitle].join(" ")}
                      >
                        {style.label}
                      </label>
                      <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="top" title={style.description}>i</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center q-nav-btn">
          <label
            onClick={handleNextButton}
            className={[`nxt-btn`, classes.nextButton].join(" ")}
          >
            NEXT
          </label>
      </div>
    </div>
  );
};

export default FirstQuestion;
