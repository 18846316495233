import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { head } from 'lodash';
import HeaderCover from '../components/HeaderCover';
import ServiceDesc from '../components/home/ServiceDesc';
import HowItWorks from '../components/home/HowItWorks';
import classes from './Home.module.css';
import image1 from './1.jpg';
import image from './homeHeaderCover.png';
import { Helmet } from 'react-helmet';
import { useHttpClient } from '../shared/hooks/http-hook';
import { useAuth } from '../shared/hooks/auth-hooks';
import { useHistory } from 'react-router-dom';
import { HolidayGiveaway } from '../components/home/HolidayGiveaway';
import RecommendedButton from '../components/home/RecommendedButton';

const HomeContainer = (props) => {
  const [searchText, setSearchText] = useState('');
  const tokenValue = JSON.parse(localStorage.getItem('userData'));
  const userDatas = JSON.parse(localStorage.getItem('userDatas'));
  const { sendRequest } = useHttpClient();
  const [userDetails, setUserDetails] = useState('');
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.history.push('/all-results', {
      search: searchText,
    });
  };

  //get user information
  useEffect(() => {
    const getData = async () => {
      try {
        const responseDetails = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
          'GET',
          {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + tokenValue.token,
          }
        );
        localStorage.setItem(
          'userDatas',
          JSON.stringify({
            userFirstName: responseDetails.first_name,
            userLastName: responseDetails.last_name,
            userEmail: responseDetails.email,
            quiz: responseDetails.quiz_attempted,
            profile_pic: responseDetails.profile_pic,
          })
        );
        setUserDetails(responseDetails);
        history.push('/');
      } catch (err) {}
    };
    getData();
  }, [tokenValue?.token]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hair Tress</title>
      </Helmet>
      <HeaderCover
        image={image1}
        searchText={searchText}
        setSearch={setSearchText}
        submit={handleSubmit}
      />
      {!tokenValue ? (
        <HowItWorks tokenValue={tokenValue} userDetails={userDetails} />
      ) : (
        <div></div>
      )}
      <ServiceDesc />
      {tokenValue ? (
        <RecommendedButton 
        quiz_attempted={userDatas?.quiz}
        />
      ) : (
        <div></div>
      )}
      
    </div>
  );
};

export default HomeContainer;
