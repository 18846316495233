import classes from "./Login.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, {
  useCallback,
  useReducer,
  useState,
} from "react";
import Swal from "sweetalert2";
import { useHttpClient } from "../shared/hooks/http-hook";
import { useHistory } from "react-router-dom";
import Input from "../shared/UI/Input";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import { Helmet } from "react-helmet";
import { useAuth } from "../shared/hooks/auth-hooks";

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value,
    };
    const updateValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid,
    };
    let updatedFormIsValid = true;
    for (const key in updateValidities) {
      updatedFormIsValid = updatedFormIsValid && updateValidities[key];
    }
    return {
      formIsValid: updatedFormIsValid,
      inputValidities: updateValidities,
      inputValues: updatedValues,
    };
  }
  return state;
};
const ChangePassword = () => {
  const { token } = useAuth();
  const [password, setPassword] = useState("");
  const [formStep, setFormStep] = useState(1);
  const history = useHistory();
  const { isLoading, sendRequest } = useHttpClient();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      oldPassword: "",  
      password: "",
      confirmPassword: ""
    },
    inputValidities: {
      oldPassword: false,
      password: false,
      confirmPassword: false
    },
    formIsValid: false,
  });
 
  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 1) {
      if (oldPassword.length <= 0 || password.length <= 0 || confirmPassword.length <= 0) {
        Swal.fire({
          title: "Error",
          text: "Please enter all fields",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } 
      else if((oldPassword.length > 75 ) || (password.length > 75 ) || (confirmPassword.length > 75 )){
        Swal.fire({
            title: "Error",
            text: "Enter valid details. Maximum length: 75 ",
            icon: "error",
            confirmButtonText: "Ok",
        });
      }
      else if(oldPassword.length > 0 && password !== confirmPassword){
        Swal.fire({
          title: "Error",
          text: "New password & Confirm password donot match",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      else  {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/auth/password/change/`,
            "POST",
            {
              "Content-Type": "application/json",
              Authorization: "Token " + token,

            },
            JSON.stringify({
              old_password: oldPassword,
              new_password1: password,
              new_password2: confirmPassword,
            })
          );
          Swal.fire({
            text: responseData.detail,
            icon: "success",
            confirmButtonText: "Ok",
          });
          localStorage.removeItem("userData");
          localStorage.removeItem("userDatas");
          history.push("/login");
          history.go("/login");
        } catch (err) {
          Swal.fire({
            title: "Error",
            text: "Invalid old password",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    } 
   
  };
  const load = <LoadingSpinner />;
  return (
    <div
      className={[
        `d-flex`,
        `justify-content-center`,
        `align-items-center`,
        classes.loginFormMainContainer,
      ].join(" ")}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Change Password</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 m-auto">
          <div className="signup-in mt-5">
      <div
        className={[
          `col-12 col-md-12`,
          `signup-wrap`,
          classes.loginFormContainer,
        ].join(" ")}
      >
        <div>
          <div className="d-flex flex-column align-items-center">
            <h1 className={[`noMarginBottom`, `text-center`].join(" ")}>
              Change Password
            </h1>
            <div
              className="mb-3 mt-2"
              style={{
                width: "50px",
                height: "8px",
                backgroundColor: "#8E5051",
              }}
            ></div>
          </div>
          <div
            className={[
              `d-flex`,
              `justify-content-center`,
              classes.formSuggestionLabelDiv,
            ].join(" ")}
          >
           
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
                <label className={[`flex-column`].join(" ")}>Old Password</label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter old Password"
                  onInputChange={inputChangeHandler}
                  required
                  errorText="Password must have minimum one special character,numeric, uppercase, lowercase letter and  minimum password length : 6"
                  password
                  setField={setOldPassword}
                />
              </div>
              <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
                <label className={[`flex-column`].join(" ")}>New Password</label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter New Password"
                  onInputChange={inputChangeHandler}
                  required
                  errorText="password must have minimum one special character,numeric, uppercase, lowercase letter and  minimum password length : 6"
                  password
                  setField={setPassword}
                />
              </div>
              <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
                <label className={[`flex-column`].join(" ")}>Confirm Password</label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Confirm Password"
                  onInputChange={inputChangeHandler}
                  required
                  errorText="password must have minimum one special character,numeric, uppercase, lowercase letter and  minimum password length : 6"
                  password
                  setField={setConfirmPassword}
                />
              </div>


            <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
              {!isLoading ? (
                <button className={[`c-btn-primary`, classes.loginButton].join(" ")} type="submit">
                  {formStep === 1 ? "Submit" : "Submit"}
                </button>
              ) : (
                <div className="d-flex justify-content-center">{load}</div>
              )}
            </div>
          </form>
        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
