import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./Footer.module.css";

const Footer = (props) => {
  const footerNav = [
    {
      id: 0,
      title: "Home",
      link: "/",
    },
    {
      id: 1,
      title: "The Craft",
      link: "/craft-products",
    },
    {
      id: 2,
      title: "The Products",
      link: "/products",
    },
    {
      id: 3,
      title: "The Pros",
      link: "/the-pros",
    },
    {
      id: 4,
      title: "The Study",
      link: "/the-study",
    },
  ];
  const social = [
    {
      id: 0,
      label: "Facebook",
      image: "assets/img/relevant/footer/facebook.svg",
    },
    {
      id: 2,
      label: "Facebook",
      image: "assets/img/relevant/footer/twitter.svg",
    },
    {
      id: 1,
      label: "Facebook",
      image: "assets/img/relevant/footer/instagram.svg",
    },
  ];
  return (
    <section className="footer-main">
    <div className={[`footer-in`, classes.footerMainDiv].join(" ")}>
      <div className="container pt-4 pb-4">
        <div className="d-flex flex-column flex-md-row">
          <div className="col-12 col-md-5 d-flex flex-column footer-firstCol">
            <h6 className={classes.footerLogoText}>TRESS</h6>
            <label style={{ color: "#000", fontSize: "17px", fontWeight: 900 }}>
              www.tresshaircompany.com
            </label>
            <div className="d-flex footer-social">
              <a href="https://m.facebook.com/tresshairco/" target="_blank" rel="noopener noreferrer">
                <div
                  className={[
                    `mr-3`,
                    `d-flex`,
                    `justify-content-center`,
                    `align-items-center`,
                    `each-icon`,
                    classes.iconDiv,
                  ].join(" ")}
                >
                  <div>
                    <i
                      className={[`fab`, `fa-facebook-f`, classes.socialIcon].join(
                        " "
                      )}
                    ></i>
                  </div>
                </div>
              </a>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.instagram.com/tresshairco/?hl=en"
              >
                <div
                  className={[
                    `mr-3`,
                    `d-flex`,
                    `justify-content-center`,
                    `align-items-center`,
                    `each-icon`,
                    classes.iconDiv,
                  ].join(" ")}
                >
                  <div>
                    <i
                      className={[`fab`, `fa-instagram`, classes.socialIcon].join(
                        " "
                      )}
                    ></i>
                  </div>
                </div>
              </a>
              <a
                target="_blank" rel="noopener noreferrer"
                href="https://www.tiktok.com/@tresshairco?_d=secCgsIARCbDRgBIAIoARI%2BCjxu4Vx7v%2Bs1CCvPl4IHdcMd5wYdcRQvkVHInueZc9EEJfcJRt3tl4whCP4u8u8BIKJjkQaUPpomorbK4qgaAA%3D%3D&language=en&sec_uid=MS4wLjABAAAA-yoQWBr3xqdeRnMhvAlMQFfZAajh4ADpyKoN1FkJG7HbTEgrAYKsgnrLaq0GDpgO&sec_user_id=MS4wLjABAAAA-yoQWBr3xqdeRnMhvAlMQFfZAajh4ADpyKoN1FkJG7HbTEgrAYKsgnrLaq0GDpgO&share_author_id=6895030289823695877&share_link_id=EDD6B1AF-AF6B-425A-B226-7CEC5DEB57C8&tt_from=sms&u_code=dfec0mbl341b79&user_id=6895030289823695877&utm_campaign=client_share&utm_medium=ios&utm_source=sms&source=h5_m
                "
              >
                <div
                  className={[
                    `mr-3`,
                    `d-flex`,
                    `justify-content-center`,
                    `align-items-center`,
                    `each-icon`,
                    classes.iconDiv,
                  ].join(" ")}
                >
                  <div>
                    <i
                      className={[`fab`, `fa-tiktok`, classes.socialIcon].join(" ")}
                    ></i>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-3 footer-secondCol">
            <div className="d-flex flex-column ">
              {footerNav.map((nav, i) => {
                return (
                  <Link  key={nav.id} className={classes.nanLink} to={nav.link}>
                    {nav.title}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="col-12 col-md-4 footer-thirdCol">
            <div>
              <p>Have any questions or feedback? Let us know!</p>
            </div>
            <h6 className={classes.footerSubscribeFormHeading}>
              <label
                className={[
                  `text-center`,
                  `c-btn-contact`,
                  
                ].join(" ")}
              >
                <a
                  className={classes.sendEmailButton}
                  href="mailto:tress@tresshaircompany.com"
                >
                  Contact Us
                </a>
              </label>
            </h6>
          </div>
        </div>
      </div>
    </div>
    
      <div className="container">
      <div className="copyright-txt">
      <ul>
        <li><Link to="/cookie">Cookie Policy</Link></li>
        <li><Link to="/privacy">Privacy Policy</Link></li>
        <li><Link to="/terms">Terms of use</Link></li>
      </ul>
      <p>© Tress Hair Company 2021</p>
      </div>
    </div>
    </section>
  );
};

export default Footer;
