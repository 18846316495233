import React, { useEffect, useState } from "react";
import { AboutHeader } from "../components/aboutus/AboutHeader";
import aboutusCoverImage from "./headerCovers/about1.jpg";
import classes from "./AboutUs.module.css";
import { Helmet } from "react-helmet";

export const AboutUs = (props) => {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
  window.scrollTo(0, 0)
  },[]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    props.history.push("/recommeded-product", {
      search: searchText,
    });
  };
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
      </Helmet>
      <div className={[`profile-sub-in`,`subpage-headcover-main`,`only-heading`,`about-us-wraper`, classes.headerCoverMainDiv].join(" ")}>
        <div
          className={[`subpage-headcover-wrap`, classes.headerCoverDiv].join(" ")}
        >
          <div
            className={[
              `container`,
              `d-flex`,
              `flex-column`,
              `justify-content-center`,
              `align-items-center`,
              `subpage-headcover`,
              classes.headerCoverHeaderDiv,
            ].join(" ")}
            style={{ minHeight: "18vh" }}
          >
            <h1
              className={[`noMarginBottom`, classes.headerCoverHeading].join(
                " "
              )}
            >
              ABOUT US
            </h1>
           
          </div>
        </div>
      </div>
      
      <div className="container my-5 subpage-in">
      <div className="about-in-image">
        <img src={aboutusCoverImage} className="img-fluid" alt="" />
      </div>
        <div className="d-flex flex-wrap">
          <div className="d-flex flex-column col-12">
          <div className={[`hair-p-o-content`,classes.para].join(" ")}>
            <p className={classes.para}>
            What is Tress? 
            </p>
            <span>
            Tress makes hair care easy, efficient and economical by personalizing it! 
            </span>
            <p className={[classes.para, "my-4"].join(" ")}>
            How Does it Work? 
            </p>
            <span>
            First, tell us about your hair by taking our Hair Quiz. Your Hair Quiz results will be used to create your Hair Profile, a detailed overview of all the things that make your beautiful hair unique! Next, we'll use your Hair Profile to match you with hair care resources catered to your hair features. Bookmark your favorite hair blogs, products, tutorials, and more so you always have the hair care resources you need!
            </span>
            <p className={classes.para}>
            Meet the Founders
            </p>
            <span>
            Tress Founders Aliyah and Hannah met while studying at Santa Clara University and have been best friends ever since. They talk about everything, but one topic that always comes up for them is hair! It started when Hannah shared her struggles to find products that tamed her frizz in the Houston humidity. Aliyah had stories of her own, one of which involved a stylist unfamiliar with managing kinky, coily hair who left chemicals from a Brazlian Blowout on Aliyah's scalp for too long...Aliyah left the salon that day with a burnt scalp and bald spots!
            <br />
            <br />
            Hannah and Aliyah knew their hair was complicated, but after surveying hundreds of women, they realized it wasn't just them. HAIR IS COMPLICATED, especially for women of color with kinky & coily hair. Instead of wasting more time and money searching for products, styles, or salons, Aliyah and Hannah decided to create Tress, so every woman can be matched with the right hair care resources.
            </span>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
