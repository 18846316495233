import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { head } from "lodash";
import HeaderCover from "../components/HeaderCover";
import AboutTress from "../components/home/AboutTress";
import ServiceDesc from "../components/home/ServiceDesc";
import HowItWorks from "../components/home/HowItWorks";
import { useAuth } from "../shared/hooks/auth-hooks";
import { useHttpClient } from "../shared/hooks/http-hook";
import SearchIcon from "./search.svg";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import { Helmet } from "react-helmet";
import Gif2 from "./new/Gif/Gif4.gif";
import Gif1 from "./new/Gif/Gif1.gif";
import classes from "./Products.module.css";
import classess from "./RecommendedProduct.module.css";
import image from "./headerCovers/cover8.jpg";
import wishlist from "./heart.svg";

const Products = (props) => {
  const { token } = useAuth();
  const [searchText, setSearchText] = useState(props.location.state.search || "");
  const [products, setProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [pros, setPros] = useState([]);
  const [recommendedpros, setRecommendedPros] = useState([]);
  const [study, setStudy] = useState([]);
  const [recommendedStudy, setRecommendedStudy] = useState([]);
  const [craft, setCrafts] = useState([]);
  const [recommendedcrafts, setRecommendedCrafts] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const myData = JSON.parse(localStorage.getItem("userDatas"));
  const tokenValue = JSON.parse(localStorage.getItem("userData"));
  const [loadingRecommendedCrafts, setLoadingRecommendedCrafts] = useState(
    false
  );
  const [loadingRecommendedProducts, setLoadingRecommendedProducts] = useState(
    false
  );
  const [loadingRecommendedPros, setLoadingRecommendedPros] = useState(false);
  const [loadingRecommendedStudies, setloadingRecommendedStudies] = useState(
    false
  );
  const [loadingAllProducts, setLoadingAllProducts] = useState(false);
  const [loadingAllPros, setLoadingAllPros] = useState(false);
  const [loadingAllCrafts, setLoadingAllCrafts] = useState(false);
  const [loadingAllStudies, setLoadingAllStudies] = useState(false);
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const [loadingMorePros, setLoadingMorePros] = useState(false);
  const [loadingMoreCrafts, setLoadingMoreCrafts] = useState(false);
  const [loadingMoreStudies, setLoadingMoreStudies] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const [showLoadProducts, setShowLoadProducts] = useState(false);
  const [showLoadPros, setShowLoadPros] = useState(false);
  const [showLoadCrafts, setShowLoadCrafts] = useState(false);
  const [showLoadStudies, setShowLoadStudies] = useState(false);

  const [loadingMoreRecommendedProducts, setLoadingMoreRecommendedProducts] = useState(false);
  const [loadingMoreRecommendedPros, setLoadingMoreRecommendedPros] = useState(false);
  const [loadingMoreRecommendedCrafts, setLoadingMoreRecommendedCrafts] = useState(false);
  const [loadingMoreRecommendedStudies, setLoadingMoreRecommendedStudies] = useState(false);
  const [
    showRecommendedLoadProductsButton,
    setShowRecommendedLoadProductsButton,
  ] = useState(false);
  const [
    showRecommendedLoadProsButton,
    setShowRecommendedLoadProsButton,
  ] = useState(false);
  const [
    showRecommendedLoadCraftsButton,
    setShowRecommendedLoadCraftsButton,
  ] = useState(false);
  const [
    showRecommendedLoadStudiesButton,
    setShowRecommendedLoadStudiesButton,
  ] = useState(false);

  const fetchProduct = async (route, type, updateState, updateLoading) => {
    updateLoading(true);
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    try {
      if(myData){
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${route}${searchquery}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token
          }
        );
        updateState(responseData.results);
        if (type === "pr" && responseData.results.length >= 10)
          setShowLoadProducts(true);
        if (type === "p" && responseData.results.length >= 10)
          setShowLoadPros(true);
        if (type === "c" && responseData.results.length >= 10)
          setShowLoadCrafts(true);
        if (type === "s" && responseData.results.length >= 10)
          setShowLoadStudies(true);
        updateLoading(false);
      }
      else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${route}${searchquery}`,
          "GET",
          {
            "Content-Type": "application/json",
          }
        );
        updateState(responseData.results);
        if (type === "pr" && responseData.results.length >= 10)
          setShowLoadProducts(true);
        if (type === "p" && responseData.results.length >= 10)
          setShowLoadPros(true);
        if (type === "c" && responseData.results.length >= 10)
          setShowLoadCrafts(true);
        if (type === "s" && responseData.results.length >= 10)
          setShowLoadStudies(true);
        updateLoading(false);
      }
      
     
    } catch (err) {
      updateLoading(false);
    }
  };
  const fetchRecommendedProduct = async (route, type, updateState, updateLoading) => {
    updateLoading(true);
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${route}${searchquery}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        }
      );
      updateState(responseData.results);
      if (type === "pr" && (responseData.results.length  >= 10))
      setShowLoadProducts(true);
      if (type === "p" && (responseData.results.length >= 10))
      setShowLoadPros(true);
      if (type === "c" && (responseData.results.length >= 10))
      setShowLoadCrafts(true);
      if (type === "s" && (responseData.results.length >= 10))
      setShowLoadStudies(true);
      updateLoading(false);
    } catch (err) {
      updateLoading(false);
    }
  };
  useEffect(() => {
    let urltoCrafts = `/product/?is_craft=True&recommended=False&search=`;
    let urltoProducts = `/product/?is_product=True&recommended=False&search=`;
    let urltoStudy = `/product/?is_study=True&recommended=False&search=`;
    let urltoPros = `/product/?is_pros=True&recommended=False&search=`;

    let type = "pr";
    fetchProduct(urltoProducts, type, setProducts, setLoadingAllProducts);
    type = "s";
    fetchProduct(urltoStudy, type, setStudy, setLoadingAllStudies);
    type = "p";
    fetchProduct(urltoPros, type, setPros, setLoadingAllPros);
    type = "c";
    fetchProduct(urltoCrafts, type, setCrafts, setLoadingAllCrafts);
  }, []);
  useEffect(() => {
    let type;
    let urltoRecommendedCrafts = `/product/?is_craft=True&recommended=True&search=`;
    let urltoRecommendedProducts = `/product/?is_product=True&recommended=True&search=`;
    let urltoRecommendedStudy = `/product/?is_study=True&recommended=True&search=`;
    let urltoRecommendedPros = `/product/?is_pros=True&recommended=True&search=`;
    type = "pr";
    if (token && myData.quiz)
    fetchRecommendedProduct(
        urltoRecommendedProducts,
        type,
        setRecommendedProducts,
        setLoadingRecommendedProducts
      );
    type = "c";
    if (token && myData.quiz)
    fetchRecommendedProduct(
        urltoRecommendedCrafts,
        type,
        setRecommendedCrafts,
        setLoadingRecommendedCrafts
      );
    type = "p";
    if (token && myData.quiz)
    fetchRecommendedProduct(
        urltoRecommendedPros,
        type,
        setRecommendedPros,
        setLoadingRecommendedPros
      );
    type = "s";
    if (token && myData.quiz)
    fetchRecommendedProduct(
        urltoRecommendedStudy,
        type,
        setRecommendedStudy,
        setloadingRecommendedStudies
      );
  }, [token]);

  const handleLoadMoreProducts = async () => {
    setLoadingMoreProducts(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_product=True&recommended=False&search=${searchquery}&limit=${products.length + 10}`;

    try {
      if(myData){
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token,

          }
        );
  
        setProducts(responseData.results);
        setLoadingMoreProducts(false);
        if (responseData.results.length  >= 10) {
          setShowLoadProducts(true);
        } else {
          setShowLoadProducts(false);
        }
      }
      else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
          }
        );
  
        setProducts(responseData.results);
        setLoadingMoreProducts(false);
        if (responseData.results.length >= 10) {
          setShowLoadProducts(true);
        } else {
          setShowLoadProducts(false);
        }
      }
      
    } catch (err) {
      setLoadingMoreProducts(false);

    }
    
  };
  const handleLoadMorePros = async () => {
    setLoadingMorePros(true);
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_pros=True&recommended=False&search=${searchquery}&limit=${pros.length + 10}`;

    try {
      if(myData){
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token,
          }
        );
       
        setPros(responseData.results);
        setLoadingMorePros(false);
        if (responseData.results.length >= 10) {
          setShowLoadPros(true);
        } else {
          setShowLoadPros(false);
        }
      }
      else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
          }
        );
       
        setPros(responseData.results);
        setLoadingMorePros(false);
        if (responseData.results.length >= 10) {
          setShowLoadPros(true);
        } else {
          setShowLoadPros(false);
        }
      }
      
    } catch (err) {
      setLoadingMorePros(false);

    }
    
  };
  const handleLoadMoreCrafts = async () => {
    setLoadingMoreCrafts(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_craft=True&recommended=False&search=${searchquery}&limit=${craft.length + 10}`;

    try {
      if(myData){
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token,
          }
        );
       
        setCrafts(responseData.results);
        setLoadingMoreCrafts(false);
        if (responseData.results.length >= 10) {
          setShowLoadCrafts(true);
        } else {
          setShowLoadCrafts(false);
        }
      }
      else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
          }
        );
       
        setCrafts(responseData.results);
        setLoadingMoreCrafts(false);
        if (responseData.results.length >= 10) {
          setShowLoadCrafts(true);
        } else {
          setShowLoadCrafts(false);
        }
      }
      
    } catch (err) {
      setLoadingMoreCrafts(false);

    }
  };
  const handleLoadMoreStudies = async () => {
      setLoadingMoreStudies(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_study=True&recommended=False&search=${searchquery}&limit=${study.length + 10}`;

    try {
      if(myData){
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenValue.token,
          }
        );
       
        setStudy(responseData.results);
        setLoadingMoreStudies(false);
        if (responseData.results.length >= 10) {
          setShowLoadStudies(true);
        } else {
          setShowLoadStudies(false);
        }
      }
      else{
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "GET",
          {
            "Content-Type": "application/json",
          }
        );
       
        setStudy(responseData.results);
        setLoadingMoreStudies(false);
        if (responseData.results.length >= 10) {
          setShowLoadStudies(true);
        } else {
          setShowLoadStudies(false);
        }
      }
     
    } catch (err) {
      setLoadingMoreStudies(false);

    }
  
  };
  const handleLoadMoreRecommendedProducts = async () => {
    setLoadingMoreRecommendedProducts(true);
     let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_product=True&recommended=True&search=${searchquery}&limit=${recommendedProducts.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,

        }
      );
     
      setRecommendedProducts(responseData.results);
      setLoadingMoreRecommendedProducts(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoadProductsButton(true);
      } else {
        setShowRecommendedLoadProductsButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedProducts(false);
    }
  
  };
  const handleLoadMoreRecommendedPros = async () => {
    setLoadingMoreRecommendedPros(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_craft=True&recommended=True&search=${searchquery}&limit=${recommendedpros.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,

        }
      );
     ;
      setRecommendedPros(responseData.results);
      setLoadingMoreRecommendedPros(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoadProsButton(true);
      } else {
        setShowRecommendedLoadProsButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedPros(false);
    }
  
  };
  const handleLoadMoreRecommendedCrafts = async () => {
      setLoadingMoreRecommendedCrafts(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_craft=True&recommended=True&search=${searchquery}&limit=${recommendedcrafts.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,

        }
      );
      
      setRecommendedCrafts(responseData.results);
      setLoadingMoreRecommendedCrafts(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoadCraftsButton(true);
      } else {
        setShowRecommendedLoadCraftsButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedCrafts(false);
    }
   
  };
  const handleLoadMoreRecommendedStudies = async () => {
    setLoadingMoreRecommendedStudies(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_study=True&recommended=True&search=${searchquery}&limit=${recommendedStudy.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token

        }
      );
      
      setRecommendedStudy(responseData.results);
      setLoadingMoreRecommendedStudies(false);
      if (responseData.results.length  >= 10) {
        setShowRecommendedLoadStudiesButton(true);
      } else {
        setShowRecommendedLoadStudiesButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedStudies(false);
    }
   
  };
  const addToFavourite = async (val, type) => {
    let urltoWishlist = `/wishlist/`;
    let formData = new FormData();
    formData.append("id", val);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoWishlist}`,
        "POST",
        {
          Authorization: "Token " + tokenValue.token,
        },
        formData
      );
    } catch (err) {
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Results</title>
      </Helmet>
      <div className={[`subpage-headcover-main`,classess.headerCoverMainDiv].join(" ")}>
        <div
          style={{
            backgroundImage: `-webkit-linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
             backgroundImage: `linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
            }}
          className={[`subpage-headcover-wrap`, classess.headerCoverDiv].join(" ")}
        >
          <div
            className={[
              `container`,
              `d-flex`,
              `flex-column`,
              `justify-content-center`,
              `align-items-center`,
              `subpage-headcover`,
              classess.headerCoverHeaderDiv,
            ].join(" ")}
            style={{ minHeight: "80vh" }}
          >
            <h1
              className={[`noMarginBottom`, classess.headerCoverHeading].join(
                " "
              )}
            >
              Results
            </h1>
           
          </div>
        </div>
      </div>

      <div className="container">
        <div>
        
          {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Crafts Recommended To You
              </h4>
              {loadingRecommendedCrafts && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                  <img alt="GIF" src={Gif1} />
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedCrafts && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedcrafts.length ? (
                        recommendedcrafts.map((craft, i) => {
                          return (
                            <a
                              className="col-12 col-md-6 mb-3"
                              target="_blank" rel="noopener noreferrer"
                              href={craft.link_1}
                              key={i}
                            >
                              <div className="row mb-4" key={i}>
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{ height: "150px" }}
                                >
                                  <div
                                    className={classess.thecraftImage}
                                    style={{
                                      backgroundImage: `url(${craft.image_external_url === null || craft.image_external_url === "" ? craft.image : craft.image_external_url})`,
                                      width: "100%",
                                      height: "100%",
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      boxShadow: "0px 0px 1px 2px #a7a7a7",
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                  
                                </div>
                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                  <div className="d-flex flex-column">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classess.thecraftHeading,
                                      ].join(" ")}
                                    >
                                      {craft.name.length > 40
                                        ? `${craft.name.substr(0, 51)}...`
                                        : craft.name}
                                    </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {craft.hair_length.length > 2 ? `${craft.hair_length.join(', ').substr(0, 13)}...`
                                       : craft.hair_length.join(', ')} 
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Density : {craft.hair_density.length > 2 ? `${craft.hair_density.join(', ').substr(0, 12)}...`
                                       : craft.hair_density.join(', ')}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Processing : {craft.hair_processing_history.length > 2 ? `${craft.hair_processing_history.join(', ').substr(0, 12)}...`
                                : craft.hair_processing_history.join(', ')}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5 className="">
                          {searchText === "" ?   "No Crafts recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"} 
                          </h5>
                        </div>
                      )}
                    </div>
                  )}
            
                </div>
              )}
               {loadingMoreRecommendedCrafts ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      {showRecommendedLoadCraftsButton && (
                        <div>
                          <button
                            className={[`c-btn-primary`,`btn-explore`,classes.loadMoreButton].join(" ")}
                            onClick={handleLoadMoreRecommendedCrafts}
                          >
                            Explore More 
                          </button>
                        </div>
                      )}
                    </div>
                  )}
            </div>
          ) : null}
          {loadingAllCrafts && (
            <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
              Crafts for you
            </h4>
          )}
          {loadingAllCrafts && (
            <div>
              <div className="row col-12 mb-5 mt-5">
                {[...Array(3)].map((study, i) => {
                  return (
                    <span
                      className="col-12 col-md-6 mb-3"
                      target="_blank" rel="noopener noreferrer"
                      key={i}
                    >
                      <div className="row mb-4">
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{ height: "150px" }}
                        >
                          <div
                            className={classes.theProductImage}
                            style={{
                              backgroundColor: "rgb(136,136,136,0.4)",
                              width: "100%",
                              height: "100%",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "5px",
                            }}
                          ></div>
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <div className="d-flex flex-column">
                           
                            <img alt="GIF" src={Gif2} />
                            <div className={`pt-2`}>
                             
                              <img alt="GIF" src={Gif1} />

                             
                              <img alt="GIF" src={Gif1} className="mt-2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          {!loadingAllCrafts && (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Crafts for you
              </h4>
              <div className="row col-12 mb-5 mt-5">
              {craft.length ? (
                craft.map((craft, j) => {
                  return (
                    <a
                      className="col-12 col-md-6 mb-3"
                      target="_blank" rel="noopener noreferrer"
                      href={craft.link_1}
                      key={j}
                    >
                      <div className="row mb-4">
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{ height: "150px" }}
                        >
                          <div
                            className={classess.thecraftImage}
                            style={{
                              backgroundImage: `url(${craft.image_external_url === null || craft.image_external_url === "" ? craft.image : craft.image_external_url})`,
                              width: "100%",
                              height: "100%",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              boxShadow: "0px 0px 1px 2px #a7a7a7",
                              borderRadius: "5px",
                            }}
                          ></div>
                         
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <div className="d-flex flex-column">
                            <h2
                              className={[
                                `noMarginBottom`,
                                classess.thecraftHeading,
                              ].join(" ")}
                            >
                              {craft.name.length > 40
                              ? `${craft.name.substr(0, 51)}...`
                              : craft.name}
                            </h2>
                            <div className={`pt-2`}>
                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Length : {craft.hair_length.length > 2 ? `${craft.hair_length.join(', ').substr(0, 13)}...`
                                : craft.hair_length.join(', ')} 
                              </p>
                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Density : {craft.hair_density.length > 2 ? `${craft.hair_density.join(', ').substr(0, 12)}...`
                                : craft.hair_density.join(', ')}
                              </p>
                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Processing : {craft.hair_processing_history.length > 2 ? `${craft.hair_processing_history.join(', ').substr(0, 12)}...`
                                : craft.hair_processing_history.join(', ')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              ): (
                <div className="d-flex justify-content-between">
                <h5>{searchText === "" ?   "No Products" : "Sorry, we couldn’t find a match for your search"} </h5>
              </div>
              )}
              </div>
            </div>
          )}
          {loadingMoreCrafts ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showLoadCrafts && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMoreCrafts}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
            {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Products Recommended To You
              </h4>
              {loadingRecommendedProducts && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                               
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                  <img alt="GIF" src={Gif1} />
                                 
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedProducts && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedProducts.length ? (
                        recommendedProducts.map((product, i) => {
                          return (
                            <a
                              className="col-12 col-md-6 mb-3"
                              target="_blank" rel="noopener noreferrer"
                              href={product.link_1}
                              key={i}
                            >
                              <div className="row mb-4">
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{
                                    height: "150px",
                                    boxShadow: "0px 0px 1px 2px #a7a7a7",
                                  }}
                                >
                                  <div
                                    className={classes.theProductImage}
                                    style={{
                                      backgroundImage: `url(${product.image_external_url === null || product.image_external_url === "" ? product.image : product.image_external_url})`,
                                      width: "100%",
                                      height: "80%",
                                      backgroundPosition: "center",
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                  
                                </div>
                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                  <div className="d-flex flex-column">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classes.theProductHeading,
                                      ].join(" ")}
                                    >
                                      {product.name.length > 40
                                        ? `${product.name.substr(0, 51)}...`
                                        : product.name}
                                    </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {product.hair_length.length > 2 ? `${product.hair_length.join(', ').substr(0, 13)}...`
                                : product.hair_length.join(', ')}
                                      </p>

                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Processing : {product.hair_processing_history.length > 2 ? `${product.hair_processing_history.join(', ').substr(0, 12)}...`
                                : product.hair_processing_history.join(', ')}
                                      </p>
                                    </div>
                                    <p
                                      className={[
                                        `noMarginBottom`,
                                        classes.theProductPara,
                                      ].join(" ")}
                                    >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5 className="col">
                          {searchText === "" ?   "No Products recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"}  
                          </h5>
                        </div>
                      )}
                    </div>
                  )}
                   {loadingMoreRecommendedProducts ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      {showRecommendedLoadProductsButton && (
                        <div>
                          <button
                            className={[`c-btn-primary`,`btn-explore`,classes.loadMoreButton].join(" ")}
                            onClick={handleLoadMoreRecommendedProducts}
                          >
                            Explore More 
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {loadingAllProducts && (
            <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
              Products for you
            </h4>
          )}
          {loadingAllProducts && (
            <div>
              <div className="row col-12 mb-5 mt-5">
                {[...Array(3)].map((study, i) => {
                  return (
                    <span
                      className="col-12 col-md-6 mb-3"
                      target="_blank" rel="noopener noreferrer"
                      key={i}
                    >
                      <div className="row mb-4">
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{ height: "150px" }}
                        >
                          <div
                            className={classes.theProductImage}
                            style={{
                              backgroundColor: "rgb(136,136,136,0.4)",
                              width: "100%",
                              height: "100%",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "5px",
                            }}
                          ></div>
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <div className="d-flex flex-column">
                            <img alt="GIF" src={Gif2} />
                            <div className={`pt-2`}>
                           
                              <img alt="GIF" src={Gif1} />

                            
                              <img alt="GIF" src={Gif1} className="mt-2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          {!loadingAllProducts && (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Products for you
              </h4>
              <div className="row col-12 mb-5 mt-5">
                {products.length ? (
                  products.map((product, j) => {
                    return (
                      <a
                        className="col-12 col-md-6 mb-3"
                        target="_blank" rel="noopener noreferrer"
                        href={product.link_1}
                        key={j}
                      >
                        <div className="row mb-4">
                          <div
                            className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                            style={{
                              height: "150px",
                              boxShadow: "0px 0px 1px 2px #a7a7a7",
                            }}
                          >
                            <div
                              className={classes.theProductImage}
                              style={{
                                backgroundImage: `url(${product.image_external_url === null || product.image_external_url === "" ? product.image : product.image_external_url})`,
                                width: "100%",
                                height: "80%",
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "5px",
                              }}
                            ></div>
                            
                          </div>
                          <div className="col-12 col-md-6 pt-md-0 pt-3">
                            <div className="d-flex flex-column">
                              <h2
                                className={[
                                  `noMarginBottom`,
                                  classes.theProductHeading,
                                ].join(" ")}
                              >
                                {product.name.length > 40
                                  ? `${product.name.substr(0, 51)}...`
                                  : product.name}
                              </h2>
                              <div className={`pt-2`}>
                                <p style={{ fontSize: "16px", color: "#888" }}>
                                  Hair Length : {product.hair_length.length > 2 ? `${product.hair_length.join(', ').substr(0, 13)}...`
                                : product.hair_length.join(', ')}
                                </p>

                                <p style={{ fontSize: "16px", color: "#888" }}>
                                  Hair Processing : {product.hair_processing_history.length > 2 ? `${product.hair_processing_history.join(', ').substr(0, 12)}...`
                                : product.hair_processing_history.join(', ')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    );
                  })
                ):(
                  <div className="d-flex justify-content-between">
                  <h5>{searchText === "" ?   "No Products" : "Sorry, we couldn’t find a match for your search"}</h5>
                </div>
                )
                }
              </div>
            </div>
          )}
          {loadingMoreProducts ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showLoadProducts && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMoreProducts}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
          {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Pros Recommended To You
              </h4>
              {loadingRecommendedPros && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                                
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                  
                                  <img alt="GIF" src={Gif1} />

                                  
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedPros && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedpros.length ? (
                        recommendedpros.map((pros, i) => {
                          return (
                            <a
                              target="_blank" rel="noopener noreferrer"
                              key={i}
                              className="col-12 col-md-6 mb-3"
                              href={pros.link_1}
                            >
                              <div className="row mb-4">
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{
                                    boxShadow:
                                      "0px 0px 5px 2px rgb(173,173,173)",
                                    borderRadius: "5px",
                                    height: "150px",
                                  }}
                                >
                                  <div
                                    className={classess.theprosImage}
                                    style={{
                                      backgroundImage: `url(${pros.image_external_url === null || pros.image_external_url === "" ? pros.image : pros.image_external_url})`,
                                      height: "80%",
                                      width: "100%",
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  ></div>
                                   
                                </div>
                                <div className="col-12 col-md-6 pt-3 pt-md-0 ">
                                  <div className="d-flex flex-column justify-content-between">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classess.theProsHeading,
                                      ].join(" ")}
                                    >
                                      {pros.name.length > 40
                                        ? `${pros.name.substr(0, 51)}...`
                                        : pros.name} 
                                    </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {pros.hair_length.length > 2 ? `${pros.hair_length.join(', ').substr(0, 13)}...`
                                       : pros.hair_length.join(', ')}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Density : {pros.hair_density.length > 2 ? `${pros.hair_density.join(', ').substr(0, 12)}...`
                                       : pros.hair_density.join(', ')}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5>{searchText === "" ?   "No Pros recommended for your hair profilee" : "Sorry, we couldn’t find a match for your search"} </h5>
                        </div>
                      )}
                    </div>
                  )}
                    {loadingMoreRecommendedPros ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      {showRecommendedLoadProsButton && (
                        <div>
                          <button
                            className={[`c-btn-primary`,`btn-explore`,classes.loadMoreButton].join(" ")}
                            onClick={handleLoadMoreRecommendedPros}
                          >
                            Explore More 
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {loadingAllPros && (
            <h4 style={{ color: "#8E5051", fontWeight: 600 }}>Pros for you</h4>
          )}
          {loadingAllPros && (
            <div>
              <div className="row col-12 mb-5 mt-5">
                {[...Array(3)].map((study, i) => {
                  return (
                    <span
                      className="col-12 col-md-6 mb-3"
                      target="_blank" rel="noopener noreferrer"
                      key={i}
                    >
                      <div className="row mb-4">
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{ height: "150px" }}
                        >
                          <div
                            className={classes.theProductImage}
                            style={{
                              backgroundColor: "rgb(136,136,136,0.4)",
                              width: "100%",
                              height: "100%",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "5px",
                            }}
                          ></div>
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <div className="d-flex flex-column">
                            
                            <img alt="GIF" src={Gif2} />
                            <div className={`pt-2`}>
                              
                              <img alt="GIF" src={Gif1} />

                             
                              <img alt="GIF" src={Gif1} className="mt-2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          {!loadingAllPros && (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Pros for you
              </h4>
              <div className="row col-12 mb-5 mt-5">
              {pros.length ? (
                pros.map((pros, j) => {
                  return (
                    <a
                      className="col-12 col-md-6 mb-3"
                      target="_blank" rel="noopener noreferrer"
                      href={pros.link_1}
                    >
                      <div className="row mb-4" key={j}>
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{
                            boxShadow: "0px 0px 5px 2px rgb(173,173,173)",
                            borderRadius: "5px",
                            height: "150px",
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${pros.image_external_url === null || pros.image_external_url === "" ? pros.image : pros.image_external_url})`,
                              height: "80%",
                              width: "100%",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                            }}
                          ></div>
                          
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <div className="d-flex flex-column">
                            <h2
                              className={[
                                `noMarginBottom`,
                                classess.theProsHeading,
                              ].join(" ")}
                            >
                              {pros.name.length > 40
                                        ? `${pros.name.substr(0, 51)}...`
                                        : pros.name} 
                            </h2>
                            <div className={`pt-2`}>
                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Length : {pros.hair_length.length > 2 ? `${pros.hair_length.join(', ').substr(0, 13)}...`
                                       : pros.hair_length.join(', ')}
                              </p>
                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Density : {pros.hair_density.length > 2 ? `${pros.hair_density.join(', ').substr(0, 12)}...`
                                       : pros.hair_density.join(', ')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              ):(
                <div className="d-flex justify-content-between">
                    <h5>{searchText === "" ?   "No Products" : "Sorry, we couldn’t find a match for your search"} </h5>
                  </div>
              )
              }
              </div>
            </div>
          )}
          {loadingMorePros ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showLoadPros && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMorePros}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
          {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Studies Recommended To You
              </h4>
              {loadingRecommendedStudies && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                               
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                  
                                  <img alt="GIF" src={Gif1} />

                                  
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedStudies && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedStudy.length ? (
                        recommendedStudy.map((study, i) => {
                          return (
                            <a
                              className="col-12 col-md-6 mb-3"
                              target="_blank" rel="noopener noreferrer"
                              href={study.link_1}
                              key={i}
                            >
                              <div className="row mb-4">
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{ height: "150px" }}
                                >
                                  <div
                                    className={classess.theStudyImage}
                                    style={{
                                      backgroundImage: `url(${study.image_external_url === null || study.image_external_url === "" ? study.image : study.image_external_url})`,
                                      width: "100%",
                                      height: "100%",
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      boxShadow: "0px 0px 1px 2px #a7a7a7",
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                  
                                </div>
                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                  <div className="d-flex flex-column">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classess.theStudyHeading,
                                      ].join(" ")}
                                    >
                                      {study.name.length > 40
                                        ? `${study.name.substr(0, 51)}...`
                                        : study.name}
                                    </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {study.hair_length.length > 2 ? `${study.hair_length.join(', ').substr(0, 13)}...`
                                       : study.hair_length.join(', ')}
                                      </p>

                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Processing : {study.hair_processing_history.length > 2 ? `${study.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : study.hair_processing_history.join(', ')}
                                      </p>
                                    </div>
                                    <p
                                      className={[
                                        `noMarginBottom`,
                                        classess.theStudyPara,
                                      ].join(" ")}
                                    >
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5>{searchText === "" ?   "No Studies recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"}</h5>
                        </div>
                      )}
                    </div>
                  )}
                   {loadingMoreRecommendedStudies ? (
                    <LoadingSpinner />
                  ) : (
                    <div>
                      {showRecommendedLoadStudiesButton && (
                        <div>
                          <button
                            className={[`c-btn-primary`,`btn-explore`,classes.loadMoreButton].join(" ")}
                            onClick={handleLoadMoreRecommendedStudies}
                          >
                            Explore More 
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {loadingAllStudies && (
            <h4 style={{ color: "#8E5051", fontWeight: 600 }} className="col">
              Studies for you
            </h4>
          )}
          {loadingAllStudies && (
            <div>
              <div className="row col-12 mb-5 mt-5">
                {[...Array(3)].map((study, i) => {
                  return (
                    <span
                      className="col-12 col-md-6 mb-3"
                      target="_blank" rel="noopener noreferrer"
                      key={i}
                    >
                      <div className="row mb-4">
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{ height: "150px" }}
                        >
                          <div
                            className={classes.theProductImage}
                            style={{
                              backgroundColor: "rgb(136,136,136,0.4)",
                              width: "100%",
                              height: "100%",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "5px",
                            }}
                          ></div>
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <div className="d-flex flex-column">
                           
                            <img alt="GIF" src={Gif2} />
                            <div className={`pt-2`}>
                            
                              <img alt="GIF" src={Gif1} />

                            
                              <img alt="GIF" src={Gif1} className="mt-2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          {!loadingAllStudies && (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }} className="col">
                Studies for you
              </h4>
              <div className="row col-12 mb-5 mt-5">
              {study.length ? (
                study.map((study, j) => {
                  return (
                    <a
                      className="col-12 col-md-6 mb-3"
                      target="_blank" rel="noopener noreferrer"
                      href={study.link_1}
                      key={j}
                    >
                      <div className="row mb-4">
                        <div
                          className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                          style={{ height: "150px" }}
                        >
                          <div
                            className={classess.theStudyImage}
                            style={{
                              backgroundImage: `url(${study.image_external_url === null || study.image_external_url === "" ? study.image : study.image_external_url})`,
                              width: "100%",
                              height: "100%",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              boxShadow: "0px 0px 1px 2px #a7a7a7",
                              borderRadius: "5px",
                            }}
                          ></div>
                         
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <div className="d-flex flex-column">
                            <h2
                              className={[
                                `noMarginBottom`,
                                classess.theStudyHeading,
                              ].join(" ")}
                            >
                              {study.name.length > 40
                                ? `${study.name.substr(0, 51)}...`
                                : study.name}
                            </h2>
                            <div className={`pt-2`}>
                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Length : {study.hair_length.length > 2 ? `${study.hair_length.join(', ').substr(0, 13)}...`
                                       : study.hair_length.join(', ')}
                              </p>

                              <p style={{ fontSize: "16px", color: "#888" }}>
                                Hair Processing : {study.hair_processing_history.length > 2 ? `${study.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : study.hair_processing_history.join(', ')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
                ):(
                  <div className="d-flex justify-content-between">
                      <h5>{searchText === "" ?   "No Products" : "Sorry, we couldn’t find a match for your search"}</h5>
                    </div>
                )
                }
              </div>
            </div>
          )}
          {loadingMoreStudies ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showLoadStudies && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMoreStudies}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
