import classes from "./Login.module.css";
import React, { useCallback, useContext, useReducer, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/index";
import { useHistory } from "react-router-dom";
import Input from "../shared/UI/Input";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import { Helmet } from "react-helmet";
// import Header from "../components/Header"
const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value,
    };
    const updateValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid,
    };
    let updatedFormIsValid = true;
    for (const key in updateValidities) {
      updatedFormIsValid = updatedFormIsValid && updateValidities[key];
    }
    return {
      formIsValid: updatedFormIsValid,
      inputValidities: updateValidities,
      inputValues: updatedValues,
    };
  }
  return state;
};
const LoginContainer = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      email: "",
      password: "",
    },
    inputValidities: {
      email: false,
      password: false,
    },
    formIsValid: false,
  });
  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );

  const submitQuiz = async (token) => {
    let quizAnswers = JSON.parse(localStorage.getItem("quiz"));
    let urltoEditandAdd = "/quiz/";
    // check to see if their is quiz data in local storage, if not, log no data detected, if so, upload the quiz data to the database
    if(quizAnswers){
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
          JSON.stringify({
            hair_texture: quizAnswers["hair_texture"],
            hair_length: quizAnswers["hair_length"],
            hair_density: quizAnswers["hair_density"],
            hair_porosity: quizAnswers["hair_porosity"],
            hair_processing_history: quizAnswers["hair_processing_history"],//data
            hair_style: quizAnswers["hair_style"],
          })
        )
        localStorage.removeItem("quiz");
        console.log("quiz sumbited, routing to recommended")
        history.go("/");
      }catch (err) {
        Swal.fire({
          title: "Error",
          text: "Internal server error",
          icon: "error",
          confirmButtonText: "Ok",
      });
      }
    }else{
      console.log("No quiz data detected")
      history.go("/");
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.length <= 0 || password.length <= 0) {
      Swal.fire({
        title: "Error",
        text: "Please fill the form",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#722526",
      });
    } 
    else if((email.length > 75 ) || (password.length > 75 )){
      Swal.fire({
          title: "Error",
          text: "Enter valid details. Maximum length: 75 ",
          icon: "error",
          confirmButtonText: "OK",
      });
    }
    else if(formState.formIsValid == false){
      Swal.fire({
        text: "Please fill valid details",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#722526",
      });
    }
    else {
       try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/auth/login/`,
          "POST",
          {
            "Content-Type": "application/json",
          },
          JSON.stringify({
            username: email,
            password: password,
          })
        );
        auth.login(null, responseData.key);
        submitQuiz(responseData.key);;
        
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Unable to log in with provided credentials",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#722526",
        });
      }
    }
  };
  const load = <LoadingSpinner />;
  return (
    <div
      className={[
        `d-flex`,
        `justify-content-center`,
        `align-items-center`,
        classes.loginFormMainContainer,
      ].join(" ")}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>

      <div className="container">
      <Link to="/" className="goto-home"> &#8592; Go to home</Link>
      <div className="signup-in">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="login-left"></div>
          </div>

      <div
        className={[
          `col-12 col-md-12 col-lg-6`,
          `signup-wrap`,
          classes.loginFormContainer,
        ].join(" ")}
      >
        <div>
          <div className="d-flex flex-column align-items-center">
            <h1 className={[`noMarginBottom`, `text-center`].join(" ")}>
              LOG IN
            </h1>
            <div
              className="mb-3 mt-2"
              style={{
                width: "50px",
                height: "8px",
                backgroundColor: "#8E5051",
              }}
            ></div>
          </div>
          <div
            className={[
              `d-flex`,
              `justify-content-center`,
              classes.formSuggestionLabelDiv,
            ].join(" ")}
          >
            <label className={[`noMarginBottom`, `text-center`].join(" ")}>
              Not a member yet? &nbsp;
              <Link to="/sign-up">
                <span
                  style={{
                    textDecoration: "underline",
                    color: "rgb(142, 80, 81)",
                  }}
                >
                  Sign Up here
                </span>
              </Link>
            </label>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit} className="mt-5">
            <div className={[`d-flex`, `flex-column`].join(" ")}>
              <label className={[`flex-column`].join(" ")}>Email *</label>
              <Input
                id="email"
                type="text"
                placeholder="Enter Email"
                onInputChange={inputChangeHandler}
                email
                required
                errorText="Please enter a valid email!"
                setField={setEmail}
              />
            </div>
            <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
              <label className={[`flex-column`].join(" ")}>Password *</label>
              <Input
                id="password"
                type="password"
                placeholder="Enter Password"
                onInputChange={inputChangeHandler}
                required
                errorText="Password must have minimum one special character,numeric, uppercase, lowercase letter and  minimum password length : 6"
                password
                setField={setPassword}
              />
            </div>
            <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
              {!isLoading ? (
                <button className={[`c-btn-primary`, classes.loginButton].join(" ")} type="submit">
                  LOGIN
                </button>
              ) : (
                <div className="d-flex justify-content-center">{load}</div>
              )}
            </div>
            <div
              className={[
                `d-flex`,
                `justify-content-center`,
                classes.formSuggestionLabelDiv,
              ].join(" ")}
            >
              <Link to="/reset-password">
                <label
                  style={{
                    textDecoration: "underline",
                    color: "rgb(142, 80, 81)",
                    cursor: "pointer",
                    marginTop:"2rem",
                  }}
                  className={[`noMarginBottom`, `text-center`].join(" ")}
                >
                  Forgot your password?
                </label>
              </Link>
            </div>
          </form>
        </div>
      </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default LoginContainer;
