import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import classes from "./Profile.module.css";
import { useAuth } from "../shared/hooks/auth-hooks";
import HeaderCoverProfile from "../components/HeaderCoverProfile";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/index";
import Input from "../shared/UI/Input";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import Camera from "./Camera.png";
import { Helmet } from "react-helmet";
import image1 from "./headerCovers/profileCover.jpg";
import { useHistory } from "react-router-dom";
import avatar from "../../src/components/avatar.png"

import ProfileSidebar from "../components/profileSidebar";

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";
const formReducer = (state, action) => {
    if (action.type === FORM_INPUT_UPDATE) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value,
        };
        const updateValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid,
        };
        let updatedFormIsValid = true;
        for (const key in updateValidities) {
            updatedFormIsValid = updatedFormIsValid && updateValidities[key];
        }
        return {
            formIsValid: updatedFormIsValid,
            inputValidities: updateValidities,
            inputValues: updatedValues,
        };
    }
    return state;
};


const Profile = () => {
    const auth = useContext(AuthContext);
    const [firstName, setFirstName] = useState("");
    const [fname, setFname] = useState("");
    const [userData, setUserData] = useState();
    const [lastName, setLastName] = useState("");
    const [lname, setLname] = useState("");
    const [address, setAddress] = useState("");
    const [addrss, setAddrss] = useState("");
    const [userProfileImage, setUserProfileImage] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const { token } = useAuth();
    const tokenValue = JSON.parse(localStorage.getItem("userData"));
    const [imageUpload, setimageUpload] = useState(avatar);
    const [zipCode, setZipCode] = useState("");
    const [zipCodeVal, setZipCodeVal] = useState("");
    const [fileName, setFileName] = useState("");
    const history = useHistory();

    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: {
            email: "",
            firstName: "",
            lastName: "",
            address: "",
        },
        inputValidities: {
            email: false,
            firstName: false,
            lastName: false,
            address: false,
        },
        formIsValid: false,
    });
    useEffect(() => {
        window.scrollTo(0, 0)

    },[]);   
    const inputChangeHandler = useCallback(
        (inputIdentifier, inputValue, inputValidity) => {
            dispatchFormState({
                type: FORM_INPUT_UPDATE,
                value: inputValue,
                isValid: inputValidity,
                input: inputIdentifier,
            });
        },
        [dispatchFormState],
    );
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            firstName.length <= 0 ||
            lastName.length <= 0 
        ) {
            Swal.fire({
                title: "Error",
                text: "Please fill the form",
                icon: "error",
                confirmButtonText: "Ok",
            });
        } 
        else if((firstName.length > 75 ) || (lastName.length > 75 )){
            Swal.fire({
                title: "Error",
                text: "Enter valid name. Maximum length: 75 ",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
        else if(zipCodeVal !="" && zipCodeVal.length != 5){
            Swal.fire({
                title: "Error",
                text: "Enter valid zip code. Zipcode length: 5",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
        else {
            let formData = new FormData();
            formData.append("first_name", firstName);
            formData.append("last_name", lastName);
            formData.append("email", addrss);
            formData.append("zipcode", zipCodeVal);
            formData.append("profile_pic", userProfileImage);
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
                    "PATCH",
                    {
                        Authorization: "Token " + tokenValue.token,
                    },
                    formData,
                );
                setIsEditing(false);            
                Swal.fire({
                    text: "Profile Updated",
                    icon: "success",
                    confirmButtonText: "Ok",
                });

                localStorage.setItem(
                    "userDatas",
                    JSON.stringify({
                      userFirstName: responseData.first_name,
                      userLastName: responseData.last_name,
                      userEmail: responseData.email,
                      quiz: responseData.quiz_attempted,
                      profile_pic: responseData.profile_pic
                    })
                  );
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
                        "GET",
                        {
                            "Content-Type": "application/json",
                            Authorization: "Token " + tokenValue.token,
                        },
                    );
                    localStorage.setItem(
                        "userDatas",
                        JSON.stringify({
                          userFirstName: responseData.first_name,
                          userLastName: responseData.last_name,
                          userEmail: responseData.email,
                          quiz: responseData.quiz_attempted,
                          profile_pic: responseData.profile_pic
                        })
                      );
                    setUserData(responseData);
                    setFname(responseData.first_name);
                    setFirstName(responseData.first_name);
                    setLname(responseData.last_name);
                    setLastName(responseData.last_name);
                    setAddrss(responseData.email);
                    setZipCode(responseData.zipcode);
                    setZipCodeVal(responseData.zipcode);
    
                    setAddress(
                        responseData.email === undefined || responseData.email === "undefined" ? "" : responseData.email,
                    );
                    if (responseData.profile_pic) setimageUpload(responseData.profile_pic);
                } catch (err) {
            
                }
                history.push("/profile")
            } catch (err) {
                Swal.fire({
                    title: "Error",
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        }
    };
    const handleUploadImage = async (e) => {
        let file = e.target.files[0];
        setUserProfileImage(file);
        setimageUpload(URL.createObjectURL(file));
        setFileName(e.target.files[0].name)
        return;
       
    };
   
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    useEffect(() => {
        const getData = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
                    "GET",
                    {
                        "Content-Type": "application/json",
                        Authorization: "Token " + tokenValue.token,
                    },
                );
                localStorage.setItem(
                    "userDatas",
                    JSON.stringify({
                      userFirstName: responseData.first_name,
                      userLastName: responseData.last_name,
                      userEmail: responseData.email,
                      quiz: responseData.quiz_attempted,
                      profile_pic: responseData.profile_pic
                    })
                  );
                setUserData(responseData);
                setFname(responseData.first_name);
                setFirstName(responseData.first_name);
                setLname(responseData.last_name);
                setLastName(responseData.last_name);
                setAddrss(responseData.email);
                setZipCode(responseData.zipcode);
                setZipCodeVal(responseData.zipcode);

                setAddress(
                    responseData.email === undefined || responseData.email === "undefined" ? "" : responseData.email,
                );
                if (responseData.profile_pic) setimageUpload(responseData.profile_pic);
            } catch (err) {
        
            }
        };
        getData();
    }, []);

    const load = <LoadingSpinner />;
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Profile</title>
            </Helmet>
            <HeaderCoverProfile image={image1} mainHeading="Member Details" />
            <div className="container">
                <div className={[`row`, `align-items-center`, `flex-column`, `flex-md-row`,`profile-icon`, classes.profileInfoRow].join(" ")}>
                    <div className={[`col-12`, `col-md-4`,`col-lg-3`].join(" ")}>
                        <div className={classes.profileImageDiv}>
                            <label
                                htmlFor="imageUpload"
                                title="Click to upload new image"
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                }}
                            >
                                <div style={{ width: "100%" }}>
                                    <div
                                        style={{
                                            backgroundImage: `url(${imageUpload})`,
                                            width: "205px",
                                            height: "205px",
                                            borderRadius: "50%",
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    ></div>
                                </div>
                                <div className={classes.profileImageUploadButtonDiv}>
                                { isEditing && 
                                    <div className={[`upload-sec`,classes.profileImageUploadButton].join(" ")}>
                                        <img src={Camera} alt="Upload" />
                                        
                                            <label htmlFor="imageUpload" className="ml-2 upload-label">
                                                Upload
                                            </label>
                                          
                                    </div>
                                     } 
                                </div>
                            </label>
                            { isEditing &&
                             <input type="file" style={{ display: "none" }} id="imageUpload" onChange={handleUploadImage} />
                            }
                        </div>
                    </div>
                    <div className={[`col-12`, `col-md-8`, `col-md-3`].join(" ")}>
                        <div className={classes.profileInfoDiv}>
                            <h2 className="noMarginBottom" style={{overflowWrap : "break-word"}}>Welcome {`${fname}`}!</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3" style={{marginBottom: "4em"}}>
                        <ProfileSidebar/>
                    </div>
                    <div className="col-lg-9">
                        <div className={classes.profileUpdateFormDiv}>
                        <form className="col-12 col-md-10 m-auto user-details" onSubmit={handleSubmit}>
                            <div className="profile-title">
                                <div className="d-flex justify-content-center">
                                    <h2 className={[`noMarginBottom`, classes.formHeading].join(" ")}>User Detail</h2>
                                </div>
                                {!isEditing && (
                                    <div className="d-flex justify-content-center ">
                                        {!isLoading ? (
                                            <button type="button" onClick={() => setIsEditing(true)} className={[classes.submitButton]}>
                                                Edit
                                            </button>
                                        ) : (
                                            <div className="d-flex justify-content-center">{load}</div>
                                        )}
                                    </div>
                                )}
                            </div>
                                <div className="d-flex flex-column mb-4">
                                    <label className={classes.formInputLabel}>First Name</label>
                                    {!isEditing && <p className={classes.detailText}>{fname}</p>}
                                    {isEditing && (
                                        <Input
                                            id="firstName"
                                            type="text"
                                            placeholder="Enter First Name"
                                            onInputChange={inputChangeHandler}
                                            required
                                            // errorText="Enter valid first name"
                                            fieldValue={firstName}
                                            setField={setFirstName}
                                            maximumLength={75 }
                                        />
                                    )}
                                </div>

                                <div className="d-flex flex-column mb-4">
                                    <label className={classes.formInputLabel}>Last Name</label>
                                    {!isEditing && <p className={classes.detailText}>{lname}</p>}
                                    {isEditing && (
                                        <Input
                                            id="lastName"
                                            type="text"
                                            placeholder="Enter Last Name"
                                            onInputChange={inputChangeHandler}
                                            required
                                            // errorText="Enter valid lastname"
                                            fieldValue={lastName}
                                            setField={setLastName}
                                            maximumLength={75 }
                                        />
                                    )}
                                </div>
                               
                                <div className="d-flex flex-column mb-4">
                                    <label className={classes.formInputLabel}>Email</label>
                                    {!isEditing && <p className={classes.detailText}>{addrss}</p>}
                                    {isEditing && (
                                        <Input
                                            id="address"
                                            type="text"
                                            placeholder="Enter Email"
                                            onInputChange={inputChangeHandler}
                                            required
                                            errorText="Please enter email"
                                            fieldValue={address}
                                            setField={setAddress}
                                            disabled
                                        />
                                    )}
                                </div>

                                <div className="d-flex flex-column mb-4">
                                    <label className={classes.formInputLabel}>Zip Code</label>
                                    {!isEditing && <p className={classes.detailText}>{zipCode}</p>}
                                    {isEditing && (
                                        <Input
                                            id="address"
                                            type="text"
                                            placeholder="Enter zipcode"
                                            onInputChange={inputChangeHandler}
                                            required
                                            // errorText="Please valid zipcode"
                                            fieldValue={zipCodeVal}
                                            setField={setZipCodeVal}
                                            maxLength={5}
                                        />
                                    )}
                                </div>

                          
                                {isEditing && (
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex justify-content-center mr-3">
                                            {!isLoading ? (
                                                <button type="submit" className={classes.submitButton}>
                                                    UPDATE
                                                </button>
                                            ) : (
                                                <div className="d-flex justify-content-center">{load}</div>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {!isLoading ? (
                                                <button type="button" onClick={() => setIsEditing(false)} className={classes.submitButton}>
                                                    Cancel
                                                </button>
                                            ) : null}
                                        </div>
                                    </div>
                                )}
                                
                                <div className=" mt-5 mb-2">
                                    <Link to="/change-password"> <span className="link-btn">Change password</span></Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Profile;
