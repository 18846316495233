import React from 'react'
// import classes from ".././Home.module.css";
import classes from "../../containers/Home.module.css"
import { Link } from "react-router-dom";

const GetStartedBtn = (props) => {
  return (
    <div>
        <div className="container getStarted-btn">
        <div
          className={[
            `d-flex flex-column`,
            `justify-content-center`,
            `align-items-center`,
          ].join(" ")}
        >
        

        {!props.tokenValue ?
          <div>
                <Link to="/questionaire">
                <p
                  className={[
                    `noMarginBottom`,
                    `text-center`,
                    `c-btn-primary`,
                    classes.getStartButton,
                  ].join(" ")}
                >
                  GET STARTED
                </p>
              </Link>
          </div>
        : 
        (
          <div>
            { props.tokenValue && props.userDetails.quiz_attempted == false  &&
              <Link to="/questionaire">
                <p
                  className={[
                    `noMarginBottom`,
                    `text-center`,
                    `c-btn-primary`,
                    classes.getStartButton,
                  ].join(" ")}
                >
                  GET STARTED
                </p>
              </Link>
            }
           { props.tokenValue && props.userDetails.quiz_attempted == true  &&
              <Link to="/profile">
                <p
                  className={[
                    `noMarginBottom`,
                    `text-center`,
                    `c-btn-primary`,
                    classes.getStartButton,
                  ].join(" ")}
                >
                  GET STARTED
                </p>
              </Link>
            }
          </div>
        )
        }

        </div>
      </div>
    </div>
  )
}

export default GetStartedBtn