import React, { useState } from "react";
import FirstQuestion from "../components/questionaire/FirstQuestion";
import SecQuestion from "../components/questionaire/SecQuestion";
import ThirdQuestion from "../components/questionaire/ThirdQuestion";
import HeaderCoverNew from "../components/HeaderCoverNew";
import FourthQuestion from "../components/questionaire/FourthQuestion";
import FifthQuestion from "../components/questionaire/FifthQuestion";
import SixthQuestion from "../components/questionaire/SixthQuestion";
import { useAuth } from "../shared/hooks/auth-hooks";
import { useHttpClient } from "../shared/hooks/http-hook";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import image1 from "./headerCovers/cover5.jpg";

const Questionaire = () => {
  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);

  const { userId, token } = useAuth();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  
  const [quesStep, setQuesStep] = useState(1);
  
  const [firstAnswer, setFirstAnswer] = useState();
  const [secAnswer, setSecAnswer] = useState();
  const [thirdAnswer, setThirdAnswer] = useState();
  const [fourthAnswer, setFourthAnswer] = useState();
  const [fifthAnswer, setFifthAnswer] = useState([]);
  const [sixthAnswer, setSixthAnswer] = useState();
  const myData = JSON.parse(localStorage.getItem("userDatas"));
  const [quizAnswers, setQuizAnswers] = useState({});

  const handleFirstAnswer = (quiz) => {
      setQuizAnswers({...quizAnswers, hair_texture: quiz})
  };

  const handleSecAnswer = (quiz) => {
      setQuizAnswers({...quizAnswers, hair_length: quiz})
  };

  const handleThirdAnswer = (quiz) => {
      setQuizAnswers({...quizAnswers, hair_density: quiz})
  };

  const handleFourthAnswer = (quiz) => {
      setQuizAnswers({...quizAnswers, hair_porosity: quiz})
  };

  const handleFifthAnswer = (quiz) => {
      setQuizAnswers({...quizAnswers, hair_processing_history: quiz})
  };

  const handleSixthAnswer = (quiz) => { 
      setQuizAnswers({...quizAnswers, hair_style: quiz})
      localStorage.setItem('quiz', JSON.stringify(quizAnswers));
      console.log(localStorage.getItem("quiz"));
  };
  
  const handleGoToNextQues = () => {
    setQuesStep(quesStep + 1);
  };
  
  const handleBackToPreviousQues = () => {
    setQuesStep(quesStep - 1);
  };

  const handleSubmit = async (data) => {
    console.log(JSON.parse(localStorage.getItem("quiz")));
    if(!token){
      history.push("/sign-up")
    }else{
    let urltoEditandAdd = "/quiz/";
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        JSON.stringify({
          hair_texture: quizAnswers["hair_texture"],
          hair_length: quizAnswers["hair_length"],
          hair_density: quizAnswers["hair_density"],
          hair_porosity: quizAnswers["hair_porosity"],
          hair_processing_history: quizAnswers["hair_processing_history"],//data
          hair_style: quizAnswers["hair_style"],
        })
      );
      Swal.fire({
        text: "Quiz submitted",
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/recommended-results");
        }
      });
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Internal server error",
        icon: "error",
        confirmButtonText: "Ok",
    });
    }
  }
  };
  
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quiz</title>
      </Helmet>
      <HeaderCoverNew
        image={image1}
        mainHeading="TRESS : HAIR QUIZ"
      />
      <div className="container quiz-main">

        {quesStep === 1 && (
          <FirstQuestion
            handleFirstAnswer={handleFirstAnswer}
            next={handleGoToNextQues}
            data={firstAnswer}
          />
        )}

        {quesStep === 2 && (
          <SecQuestion
            handleSecAnswer={handleSecAnswer}
            next={handleGoToNextQues}
            data={secAnswer}
            back={handleBackToPreviousQues}
          />
        )}

        {quesStep === 3 && (
          <ThirdQuestion
            handleThirdAnswer={handleThirdAnswer}
            next={handleGoToNextQues}
            data={thirdAnswer}
            back={handleBackToPreviousQues}

          />
        )}

        {quesStep === 4 && (
          <FourthQuestion
            handleFourthAnswer={handleFourthAnswer}
            next={handleGoToNextQues}
            data={fourthAnswer}
            back={handleBackToPreviousQues}

          />
        )}

        {quesStep === 5 && (
          <FifthQuestion
            loading={isLoading}
            //handleSubmit={handleSubmit}
            handleFifthAnswer={handleFifthAnswer}
            next={handleGoToNextQues}
            data={fifthAnswer}
            back={handleBackToPreviousQues}

          />
        )}

        {quesStep === 6 && (
          <SixthQuestion
            loading={isLoading}
            handleSubmit={handleSubmit}
            handleSixthAnswer={handleSixthAnswer}
            next={handleGoToNextQues}
            data={sixthAnswer}
            back={handleBackToPreviousQues}
          />
        )}
       
      </div>
    </div>
  );
};

export default Questionaire;
