import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../shared/hooks/auth-hooks";
import { useHttpClient } from "../shared/hooks/http-hook";
import SearchIcon from "./search.svg";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import { Helmet } from "react-helmet";

import classes from "./Products.module.css";
import classess from "./RecommendedResults.module.css";
import clas from "./Wishlist.module.css";
import Gif2 from "./new/Gif/Gif4.gif";
import Gif1 from "./new/Gif/Gif1.gif";
import image from "./headerCovers/profileCover.jpg";
import Camera from "./Camera.png";

import ProfileSidebar from "../components/profileSidebar";
import wishlist from "./heart.svg";
import Swal from "sweetalert2";
import avatar from "../../src/components/avatar.png"


const Wishlist = (props) => {
  const { userId, token } = useAuth();
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [recommendedpros, setRecommendedPros] = useState([]);
  const [recommendedStudy, setRecommendedStudy] = useState([]);
  const [recommendedcrafts, setRecommendedCrafts] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const myData = JSON.parse(localStorage.getItem("userDatas"));
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const [loadingMorePros, setLoadingMorePros] = useState(false);
  const [loadingMoreCrafts, setLoadingMoreCrafts] = useState(false);
  const [loadingMoreStudies, setLoadingMoreStudies] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [imageUpload, setimageUpload] = useState(avatar);
  const [userProfileImage, setUserProfileImage] = useState("");
  const [fileName, setFileName] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("userDatas"));
  const tokenValue = JSON.parse(localStorage.getItem("userData"));
  const [loadingMoreRecommendedProducts, setLoadingMoreRecommendedProducts] = useState(false);
  const [loadingMoreRecommendedPros, setLoadingMoreRecommendedPros] = useState(false);
  const [loadingMoreRecommendedCrafts, setLoadingMoreRecommendedCrafts] = useState(false);
  const [loadingMoreRecommendedStudies, setLoadingMoreRecommendedStudies] = useState(false);
  const [
    showRecommendedLoadProductsButton,
    setShowRecommendedLoadProductsButton,
  ] = useState(false);
  const [
    showRecommendedLoadProsButton,
    setShowRecommendedLoadProsButton,
  ] = useState(false);
  const [
    showRecommendedLoadCraftsButton,
    setShowRecommendedLoadCraftsButton,
  ] = useState(false);
  const [
    showRecommendedLoadStudiesButton,
    setShowRecommendedLoadStudiesButton,
  ] = useState(false);
  const [loadingRecommendedCrafts, setLoadingRecommendedCrafts] = useState(
    false
  );
  const [loadingRecommendedProducts, setLoadingRecommendedProducts] = useState(
    false
  );
  const [loadingRecommendedPros, setLoadingRecommendedPros] = useState(false);
  const [loadingRecommendedStudies, setloadingRecommendedStudies] = useState(
    false
  );

  const [feedBackStatus,setFeedBackStatus] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
   if(userDetails){
      if(userDetails?.profile_pic != null){
         setimageUpload(userDetails.profile_pic);
       }
      setFname(userDetails.userFirstName);
      setLname(userDetails.userLastName);
   }
   window.scrollTo(0, 0)
   },[]); 

  const fetchProduct = async (
    route,
    type,
    updateState,
    updateLoading,
    displayLoadMore
  ) => {
    updateLoading(true);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${route}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenValue.token,

        }
      );
      if (responseData.results.length >= 4) 
      {
        displayLoadMore(true);
      }
      else{
        displayLoadMore(false);
      }
      updateState(responseData.results);
      updateLoading(false);
    } catch (err) {
      updateLoading(false);
    }
  };


  const $ = window.$
  useEffect(() => {
   $("[data-toggle=popover]").popover({
      html: true, 
     content: function() {
            return $('#popover-content').html();
          }
  });
  },[]);

  useEffect(() => {
    let type;
    let urltoRecommendedCrafts = `/product/?wishlist=True&is_craft=True&limit=4`;
    let urltoRecommendedProducts = `/product/?wishlist=True&is_product=True&limit=4`;
    let urltoRecommendedStudy = `/product/?wishlist=True&is_study=True&limit=4`;
    let urltoRecommendedPros = `/product/?wishlist=True&is_pros=True&limit=4`;
    type = "pr";
    if (token)
      fetchProduct(
         urltoRecommendedProducts,
        type,
        setRecommendedProducts,
        setLoadingRecommendedProducts,
        setShowRecommendedLoadProductsButton
      );
    type = "c";
    if (token)
      fetchProduct(
         urltoRecommendedCrafts,
        type,
        setRecommendedCrafts,
        setLoadingRecommendedCrafts,
        setShowRecommendedLoadCraftsButton
      );
    type = "p";
    if (token)
      fetchProduct(
         urltoRecommendedPros,
        type,
        setRecommendedPros,
        setLoadingRecommendedPros,
        setShowRecommendedLoadProsButton
      );
    type = "s";
    if (token)
      fetchProduct(
         urltoRecommendedStudy,
        type,
        setRecommendedStudy,
        setloadingRecommendedStudies,
        setShowRecommendedLoadStudiesButton
      );
  }, [token]);
 

  const handleLoadMoreRecommendedProducts = async () => {
    setLoadingMoreRecommendedProducts(true);
    let urltoEditandAdd = `/product/?wishlist=True&is_product=True&limit=${recommendedProducts.length + 4}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenValue.token,

        }
      );
      setRecommendedProducts(responseData.results);
      setLoadingMoreRecommendedProducts(false);
      if (responseData.results.length >= 4) {
        setShowRecommendedLoadProductsButton(true);
      } else {
        setShowRecommendedLoadProductsButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedProducts(false);
    }
   
  };
  const handleLoadMoreRecommendedPros = async () => {
    setLoadingMoreRecommendedPros(true);
    let urltoEditandAdd = `/product/?wishlist=True&is_pros=True&limit=${recommendedpros.length + 4}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenValue.token,

        }
      );
      setRecommendedPros(responseData.results);
      setLoadingMoreRecommendedPros(false);
      if (responseData.results.length >= 4) {
        setShowRecommendedLoadProsButton(true);
      } else {
        setShowRecommendedLoadProsButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedPros(false);
    }
  
  };
  const handleLoadMoreRecommendedCrafts = async () => {
    setLoadingMoreRecommendedCrafts(true);    
    let urltoEditandAdd = `/product/?wishlist=True&is_craft=True&limit=${recommendedcrafts.length + 4}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenValue.token,

        }
      );
      setRecommendedCrafts(responseData.results);
      setLoadingMoreRecommendedCrafts(false);
      if (responseData.results.length >= 4) {
        setShowRecommendedLoadCraftsButton(true);
      } else {
        setShowRecommendedLoadCraftsButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedCrafts(false);
    }
  
    
  };
  const handleLoadMoreRecommendedStudies = async () => {
    setLoadingMoreRecommendedStudies(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?wishlist=True&is_study=True&limit=${recommendedStudy.length + 4}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenValue.token,

        }
      );
  
      setRecommendedStudy(responseData.results);
      setLoadingMoreRecommendedStudies(false);
      if (responseData.results.length >= 4) {
        setShowRecommendedLoadStudiesButton(true);
      } else {
        setShowRecommendedLoadStudiesButton(false);
      }
    } catch (err) {
      setLoadingMoreRecommendedStudies(false);
    }
   
  };
  const addToFavourite = async (val, item) => {
    let type;
    let urltoWishlist = `/wishlist/`;
    let urltoRecommendedCrafts = `/product/?wishlist=True&is_craft=True&limit=4`;
    let urltoRecommendedProducts = `/product/?wishlist=True&is_product=True&limit=4`;
    let urltoRecommendedStudy = `/product/?wishlist=True&is_study=True&limit=4`;
    let urltoRecommendedPros = `/product/?wishlist=True&is_pros=True&limit=4`;
    let formData = new FormData();
    formData.append("id", val);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoWishlist}`,
        "POST",
        {
          Authorization: "Token " + tokenValue.token,
        },
        formData
      );
     
      type = item;
      if (responseData && type == "isProduct")
        fetchProduct(
           urltoRecommendedProducts,
          type,
          setRecommendedProducts,
          setLoadingRecommendedProducts,
          setShowRecommendedLoadProductsButton
        );
      if (responseData && type == "isCraft")
        fetchProduct(
           urltoRecommendedCrafts,
          type,
          setRecommendedCrafts,
          setLoadingRecommendedCrafts,
          setShowRecommendedLoadCraftsButton
        );
      if (responseData && type == "isPros")
        fetchProduct(
           urltoRecommendedPros,
          type,
          setRecommendedPros,
          setLoadingRecommendedPros,
          setShowRecommendedLoadProsButton
        );
      if (responseData && type == "isStudy")
        fetchProduct(
           urltoRecommendedStudy,
          type,
          setRecommendedStudy,
          setloadingRecommendedStudies,
          setShowRecommendedLoadStudiesButton
        );
    } catch (err) {
    }
  };

  const handleFeedback = (id, item) =>{
    setSelectedProduct(id);
    setFeedBackStatus(item)  
  };

  const handleFeedbackSubmit = async (e) =>{
    e.preventDefault();
    let urltoFeedback= "/feedback/";
    let type;
    let urltoRecommendedCrafts = `/product/?wishlist=True&is_craft=True&limit=4`;
    let urltoRecommendedProducts = `/product/?wishlist=True&is_product=True&limit=4`;
    let urltoRecommendedStudy = `/product/?wishlist=True&is_study=True&limit=4`;
    let urltoRecommendedPros = `/product/?wishlist=True&is_pros=True&limit=4`;
    let formData = new FormData();
    formData.append("id", selectedProduct);
    if(feedBackStatus == "True"){
      formData.append("liked", "True");
    }
    else{
      formData.append("disliked", "True");
    }
    formData.append("comment", comment);
   
       try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoFeedback}`,
        "POST",
        {
          Authorization: "Token " + tokenValue.token,
        },
        formData
      );
      if(responseData){
        setSelectedProduct("");
        setFeedBackStatus("");
        setComment("");
        
        type = "pr";
        if (token)
          fetchProduct(
            urltoRecommendedProducts,
            type,
            setRecommendedProducts,
            setLoadingRecommendedProducts,
            setShowRecommendedLoadProductsButton
          );
        type = "c";
        if (token)
          fetchProduct(
            urltoRecommendedCrafts,
            type,
            setRecommendedCrafts,
            setLoadingRecommendedCrafts,
            setShowRecommendedLoadCraftsButton
          );
        type = "p";
        if (token)
          fetchProduct(
            urltoRecommendedPros,
            type,
            setRecommendedPros,
            setLoadingRecommendedPros,
            setShowRecommendedLoadProsButton
          );
        type = "s";
        if (token)
          fetchProduct(
            urltoRecommendedStudy,
            type,
            setRecommendedStudy,
            setloadingRecommendedStudies,
            setShowRecommendedLoadStudiesButton
          );
        $("#popover-content").modal("hide");
        Swal.fire({
          title: "Success",
          text: responseData.message,
          icon: "success",
          confirmButtonText: "Ok",
      });
      }
      }
      catch{
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
      });
      }

    
   
    

  };
  const onPopUpClose =  () =>{
  setSelectedProduct("");
  setFeedBackStatus("");
  setComment("");

  };
  const load = <LoadingSpinner />;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wishlist</title>
      </Helmet>
      <div className={[`profile-sub-in`,`subpage-headcover-main`, classess.headerCoverMainDiv].join(" ")}>
        <div
          style={{
            backgroundImage: `-webkit-linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
             backgroundImage: `linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
            }}
          className={[`subpage-headcover-wrap`, classess.headerCoverDiv].join(" ")}
        >
          <div
            className={[
              `container`,
              `d-flex`,
              `flex-column`,
              `justify-content-center`,
              `align-items-center`,
              `subpage-headcover`,
              classess.headerCoverHeaderDiv,
            ].join(" ")}
            style={{ minHeight: "60vh" }}
          >
            <h1
              className={[`noMarginBottom`, classess.headerCoverHeading].join(
                " "
              )}
            >
              Wishlist
            </h1>
            
          </div>
        </div>
      </div>

      <div className="container">
      <div className={[`row`, `align-items-center`, `flex-column`, `flex-md-row`,`profile-icon`, clas.profileInfoRow].join(" ")}>
         <div className={[`col-12`, `col-md-4`,`col-lg-3`].join(" ")}>
            <div className={clas.profileImageDiv}>
                  <label
                     htmlFor="imageUpload"
                     title="Click to upload new image"
                     style={{
                        cursor: "pointer",
                        display: "flex",
                     }}
                  >
                     <div style={{ width: "100%" }}>
                        <div
                              style={{
                                 backgroundImage: `url(${imageUpload})`,
                                 width: "205px",
                                 height: "205px",
                                 borderRadius: "50%",
                                 backgroundPosition: "center",
                                 backgroundSize: "cover",
                                 backgroundRepeat: "no-repeat",
                              }}
                        ></div>
                     </div>
                     <div className={clas.profileImageUploadButtonDiv}>
                        <div className={classes.profileImageUploadButton}>
                             
                        </div>
                     </div>
                  </label>
                 
            </div>
         </div>
         <div className={[`col-12`, `col-md-8`, `col-md-3`].join(" ")}>
            <div className={clas.profileInfoDiv}>
                  <h2 className="noMarginBottom" style={{overflowWrap : "break-word"}}>Welcome {`${fname}`}!</h2>
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-lg-3">
            <ProfileSidebar/>
         </div>

         <div className="col-lg-9 wishlist-products">
            <div>
               <div className="d-flex flex-column wishlist-main">
                  <h4 style={{color: "rgb(142, 80, 81)", fontWeight: "600"}}>Crafts</h4>
                  <div className="row mt-3">
                     <div className="row col-12 mb-3 mt-5">
                     {recommendedcrafts.length ? (
                        recommendedcrafts.map((craft, i) => {
                          return (
                        <div className="col-12 col-md-6 mb-5">
                           
                           <a className="row mb-3">
                              <div className="col-12 col-md-5 d-flex align-items-center justify-content-center" style={{height: "150px"}}>
                              <div className={classes.theProductImage} 
                                style={{ 
                                   backgroundImage: `url(${craft.image_external_url === null || craft.image_external_url === "" ? craft.image : craft.image_external_url})`,
                                   width: "100%",
                                   height: "80%",
                                   backgroundPosition: "center", 
                                   backgroundSize: "cover", 
                                   backgroundRepeat: "no-repeat", 
                                   borderRadius: "5px"}}></div>
                                 <div className="make-wish">
                                    <a  className="active" onClick={()=> addToFavourite(craft.id,"isCraft")}><img src={wishlist} className="img-fluid" alt="" /></a>
                                  </div>
                              </div>
                              <div className="col-12 col-md-7 pt-md-0 pt-3 d-pl-0">
                                <a target="_blank" href={craft.link_1}>
                                 <div className="d-flex flex-column">
                                    <h2 className={[`noMarginBottom`,classes.theProductHeading].join(" ")}>{craft.name.length > 20
                                        ? `${craft.name.substr(0, 30)}...`
                                        : craft.name}</h2>
                                    <div className="pt-2">
                                       <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Length  <span className="pro-cat"> {craft.hair_length.length > 2 ? `${craft.hair_length.join(', ').substr(0, 13)}...`
                                       : craft.hair_length.join(', ')}</span></p>
                                       <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Processing  <span className="pro-cat"> {craft.hair_processing_history.length > 2 ? `${craft.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : craft.hair_processing_history.join(', ')}</span></p>
                                    </div>
                                    <p className="noMarginBottom Products_theProductPara__AbBkT"></p>
                                 </div>
                                 </a>
                              </div>
                           </a>
                           <div className="pr-15 d-flex flex-column">
                              <ul className="like-area">
                                 <li className={craft.is_liked ? "active" : "" }><a data-toggle="modal" data-target="#popover-content" type="button" ><i className="fa fa-thumbs-up" onClick={()=>handleFeedback(craft.id, "True")}></i></a></li> 
                                 <li className={craft.is_disliked ? "active" : "" }><a data-toggle="modal" data-target="#popover-content" type="button"><i  className="fa fa-thumbs-down" onClick={()=>handleFeedback(craft.id, "False")}></i></a></li>
                                 
                                

                              </ul>
                           </div>
                        </div>                    
                        
                          )
                        })
                     ): (<div className="d-flex justify-content-between">
                     <h5 className="col">
                       No crafts
                     </h5>
                   </div>) }
                   
                     </div>
                     {loadingMoreRecommendedCrafts ? (
                    <div className="text-center">
                        <LoadingSpinner />
                    </div>
                    ) : (
                      <div>
                        {showRecommendedLoadCraftsButton && (
                          <div className="loadmore-btn-sec">
                          <a className="radius-btn c-btn-primary" onClick={handleLoadMoreRecommendedCrafts}>LOAD MORE</a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
               </div>
               <div></div>
               <div className="d-flex flex-column mt-5 mb-5 wishlist-main">
                  <h4 style={{color: "rgb(142, 80, 81)", fontWeight: "600"}}>Products</h4>
                  <div className="row mt-3">
                     <div className="row col-12 mb-3 mt-5">
                     {recommendedProducts.length ? (
                        recommendedProducts.map((product, i) => {
                          return (
                         <div className="col-12 col-md-6 mb-5">
                       
                           <a className="row mb-3" >
                              
                                 <div className="col-12 col-md-5 d-flex align-items-center justify-content-center" style={{height: "150px"}}>
                                    <div className={classes.theProductImage} 
                                    style={{ 
                                       backgroundImage: `url(${product.image_external_url === null || product.image_external_url === "" ? product.image : product.image_external_url})`,
                                       width: "100%",
                                       height: "80%",
                                       backgroundPosition: "center", 
                                       backgroundSize: "cover", 
                                       backgroundRepeat: "no-repeat", 
                                       borderRadius: "5px"}}></div>
                                        <div className="make-wish">
                                        <a  className="active" onClick={()=> addToFavourite(product.id,"isProduct")}><img src={wishlist} className="img-fluid" alt="" /></a>
                                      </div>
                                 </div>
                                 <div className="col-12 col-md-7 pt-md-0 pt-3 d-pl-0">
                                   <a target="_blank" href={product.link_1}>
                                    <div className="d-flex flex-column">
                                       <h2 className={[`noMarginBottom`,classes.theProductHeading].join(" ")}>{product.name.length > 20
                                        ? `${product.name.substr(0, 30)}...`
                                        : product.name}</h2>
                                       <div className="pt-2">
                                          <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Length <span className="pro-cat"> {product.hair_length.length > 2 ? `${product.hair_length.join(', ').substr(0, 13)}...`
                                       : product.hair_length.join(', ')} </span></p>
                                          <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Processing <span className="pro-cat"> {product.hair_processing_history.length > 2 ? `${product.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : product.hair_processing_history.join(', ')} </span></p>
                                       </div>
                                       <p className="noMarginBottom Products_theProductPara__AbBkT"></p>
                                    </div>
                                    </a>
                                 </div>
                              
                           </a>
                           <div className="pr-15 d-flex flex-column">
                                 <ul className="like-area">
                                    <li className={product.is_liked ? "active" : "" }><a data-toggle="modal"  data-target="#popover-content"type="button" ><i className="fa fa-thumbs-up"  onClick={()=>handleFeedback(product.id, "True")} ></i></a></li> 
                                    <li className={product.is_disliked ? "active" : "" }><a data-toggle="modal"  data-target="#popover-content" type="button" ><i  className="fa fa-thumbs-down" onClick={()=>handleFeedback(product.id, "False")}></i></a></li>
                                 </ul>   
                           </div>  
                               
                        </div> 
                       
                          )
                        })
                         
                     ): (
                        <div className="d-flex justify-content-between">
                          <h5 className="col">
                            No products
                          </h5>
                        </div>
                      )}
                     
                     </div>
                     {loadingMoreRecommendedProducts ? (
                      <div className="text-center">
                          <LoadingSpinner />
                      </div>
                      ) : (
                        <div>
                          {showRecommendedLoadProductsButton && (
                            <div className="loadmore-btn-sec">
                            <a className="radius-btn c-btn-primary" onClick={handleLoadMoreRecommendedProducts}>LOAD MORE</a>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
               </div>
               <div></div>
               <div className="d-flex flex-column mt-5 mb-5 wishlist-main">
                  <h4 style={{color: "rgb(142, 80, 81)", fontWeight: "600"}}>Pros</h4>
                  <div className="row mt-3">
                     <div className="row col-12 mb-3 mt-5">
                     {recommendedpros.length ? (
                        recommendedpros.map((pros, i) => {
                          return (
                        <div className="col-12 col-md-6 mb-5">

                           <a className="row mb-3" >
                                 <div className="col-12 col-md-5 d-flex align-items-center justify-content-center" style={{height: "150px"}}>
                                    <div className={classes.theProductImage}  style={{backgroundImage: `url(${pros.image_external_url === null || pros.image_external_url === "" ? pros.image : pros.image_external_url})`, width: "100%", height: "80%", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", borderRadius: "5px"}}></div>
                                    <div className="make-wish">
                                        <a  className="active" onClick={()=> addToFavourite(pros.id, "isPros")}><img src={wishlist} className="img-fluid" alt="" /></a>
                                      </div>
                                 </div>
                                 <div className="col-12 col-md-7 pt-md-0 pt-3 d-pl-0">
                                   <a target="_blank" href={pros.link_1}>
                                    <div className="d-flex flex-column">
                                       <h2 className={[`noMarginBottom`,classes.theProductHeading].join(" ")}>{pros.name.length > 20
                                        ? `${pros.name.substr(0, 30)}...`
                                        : pros.name}</h2>
                                       <div className="pt-2">
                                          <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Length <span className="pro-cat"> {pros.hair_length.length > 2 ? `${pros.hair_length.join(', ').substr(0, 13)}...`
                                       : pros.hair_length.join(', ')} </span></p>
                                          <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Processing <span className="pro-cat"> {pros.hair_processing_history.length > 2 ? `${pros.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : pros.hair_processing_history.join(', ')} </span></p>
                                       </div>
                                       <p className="noMarginBottom Products_theProductPara__AbBkT"></p>
                                    </div>
                                    </a>
                                 </div>
                           </a>
                           <div className="pr-15 d-flex flex-column">
                                 <ul className="like-area">
                                    <li className={pros.is_liked ? "active" : "" }><a data-toggle="modal"  data-target="#popover-content"type="button" ><i className="fa fa-thumbs-up" onClick={()=>handleFeedback(pros.id, "True")}></i></a></li> 
                                    <li className={pros.is_disliked ? "active" : "" }><a data-toggle="modal"  data-target="#popover-content" type="button" ><i  className="fa fa-thumbs-down" onClick={()=>handleFeedback(pros.id, "False")}></i></a></li>
                                 </ul>   
                           </div>  
                        </div>
                           
                          )
                        })
                     ): (
                     <div className="d-flex justify-content-between">
                     <h5 className="col">
                       No pros
                     </h5>
                   </div>)}
                   
                        </div>
                        {loadingMoreRecommendedPros ? (
                          <div className="text-center">
                              <LoadingSpinner />
                          </div>
                        ) : (
                        <div>
                          {showRecommendedLoadProsButton && (
                            <div className="loadmore-btn-sec">
                            <a className="radius-btn c-btn-primary" onClick={handleLoadMoreRecommendedPros}>LOAD MORE</a>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
               </div>
               <div></div>
               <div className="d-flex flex-column mt-5 mb-5 wishlist-main">
                  <h4 style={{color: "rgb(142, 80, 81)", fontWeight: "600"}}>Studies</h4>
                  <div className="row mt-3">
                     <div className="row col-12 mb-3 mt-5">
                     {recommendedStudy.length ? (
                        recommendedStudy.map((study, i) => {
                          return (
                        <div className="col-12 col-md-6 mb-5">
                           <a className="row mb-3" >
                                 <div className="col-12 col-md-5 d-flex align-items-center justify-content-center" style={{height: "150px"}}>
                                    <div className={classes.theProductImage} style={{backgroundImage: `url(${study.image_external_url === null || study.image_external_url === "" ? study.image : study.image_external_url})`, width: "100%", height: "80%", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", borderRadius: "5px"}}></div>
                                    <div className="make-wish">
                                        <a  className="active" onClick={()=> addToFavourite(study.id, "isStudy")}><img src={wishlist} className="img-fluid" alt="" /></a>
                                    </div>
                                 </div>
                                 <div className="col-12 col-md-7 pt-md-0 pt-3 d-pl-0">
                                   <a target="_blank" href={study.link_1}>
                                    <div className="d-flex flex-column">
                                       <h2 className={[`noMarginBottom`,classes.theProductHeading].join(" ")}>{study.name.length > 20
                                        ? `${study.name.substr(0, 30)}...`
                                        : study.name}</h2>
                                       <div className="pt-2">
                                          <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Length <span className="pro-cat"> {study.hair_length.length > 2 ? `${study.hair_length.join(', ').substr(0, 13)}...`
                                       : study.hair_length.join(', ')} </span></p>
                                          <p style={{fontSize: "16px", color: "rgb(136, 136, 136)"}}>Hair Processing <span className="pro-cat"> {study.hair_processing_history.length > 2 ? `${study.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : study.hair_processing_history.join(', ')} </span></p>
                                       </div>
                                       <p className="noMarginBottom Products_theProductPara__AbBkT"></p>
                                    </div>
                                    </a>
                              </div>
                           </a>
                           <div className="pr-15 d-flex flex-column">
                                 <ul className="like-area">
                                    <li className={study.is_liked ? "active" : "" }><a data-toggle="modal"  data-target="#popover-content"type="button" ><i className="fa fa-thumbs-up"  onClick={()=>handleFeedback(study.id, "True")}></i></a></li> 
                                    <li className={study.is_disliked ? "active" : "" }><a data-toggle="modal"   data-target="#popover-content"type="button"><i  className="fa fa-thumbs-down" onClick={()=>handleFeedback(study.id, "False")}></i></a></li>
                                 </ul>   
                           </div>  
                        </div>
                          )
                        })
                     ): (
                        <div className="d-flex justify-content-between">
                        <h5 className="col">
                          No studies
                        </h5>
                      </div>)
                      }
                      
                     </div>
                     
                     {loadingMoreRecommendedStudies ? (
                        <div className="text-center">
                            <LoadingSpinner />
                        </div>
                        ) : (
                        <div>
                           {showRecommendedLoadStudiesButton && (
                              <div className="loadmore-btn-sec">
                              <a className="radius-btn c-btn-primary" onClick={handleLoadMoreRecommendedStudies}>LOAD MORE</a>
                              </div>
                           )}
                        </div>
                        )}
                  </div>
               </div>
               <div></div>
            </div>
         </div>
      </div>

      </div>
       {/* Like/Dislike Modal Start */}
       <div className="modal fade" id="popover-content" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Write your comment</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onPopUpClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                <form onSubmit={handleFeedbackSubmit} className="form-inline" role="form">
                    <div className="form-group flex-column w-100 c-poppover"> 
                      <textarea className="form-control w-100" placeholder="Write a comment" type="text" value ={comment} onChange={(e) => {setComment(e.target.value);}} maxLength="250"></textarea>
                      <div className="d-flex popover-btn mt-2">
                          <button className="btn radius-btn mr-2" id="phSearchButton"onClick={onPopUpClose}  data-dismiss="modal" >Cancel </button>
                          {!isLoading ? (
                          <button className="btn radius-btn btn-xs" id="searchButton" type="submit">Submit</button>
                          ):(
                            <div className="d-flex justify-content-center">{load}</div>
                          )
                          }
                      </div>
                    </div>
                </form>
                </div>
              </div>
          </div>
        </div>
        {/* Like/Dislike Modal end*/}
    </div>
    
  );
};

export default Wishlist;
