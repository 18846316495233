import React from "react";
import { Link, useHistory } from "react-router-dom";

const ProfileSidebar = (props) => {
  const history = useHistory();
  const memberClass = history.location.pathname === "/profile" ? "active" : "";
  const hairProfileClass = history.location.pathname === "/hair-profile-overview" ? "active" : "";
  const wishlistClass = history.location.pathname === "/wishlist" ? "active" : "";

  return (
    <div className="d-flex h-100">
    <div className="profile-sidebar">
      <ul>
        <li className={memberClass}> <Link to="/profile">Member Details </Link></li>
        <li className={hairProfileClass}><Link to="/hair-profile-overview">Hair Profile Overview </Link></li>
        <li className={wishlistClass}><Link to="/wishlist">Wishlist </Link></li>
      </ul>
    </div>
    </div>
  );
};

export default ProfileSidebar;
