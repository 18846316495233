import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../shared/hooks/auth-hooks";
import { useHttpClient } from "../shared/hooks/http-hook";
import SearchIcon from "./search.svg";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import { Helmet } from "react-helmet";

import classes from "./Products.module.css";
import classess from "./RecommendedResults.module.css";
import Gif2 from "./new/Gif/Gif4.gif";
import Gif1 from "./new/Gif/Gif1.gif";
import image from "./headerCovers/cover8.jpg";
import { filter } from "lodash";
import wishlist from "./heart.svg";

const RecommendedResults = (props) => {
  const hairTextures = [
    {
      id: 1,
      title: "Straight",
      image: "",
      answer: 1,
      label: "1A/1B",
      description: "Your gorgeous hair is straight, soft, and shiny. It may not hold curls well, but it retains moisture extremely well, allowing your natural oils to spread from your scalp to the ends of each hair strand. To achieve optimal results, be mindful of the balance between stripping your scalp of its natural oils and keeping it hydrated. There is such a thing as overwashing! Washing too regularly will rid your scalp of its naturally produced oils, and cause it to overproduce oils, resulting in oily hair. Use lightweight products that will not weigh your hair down.",
      code: "1A1B"
    },
    {
      id: 2,
      title: "Straight with Body",
      image: "",
      answer: 2,
      label: "1C",
      description: "Your gorgeous hair is straight, but has voluminous body! It may not hold curls well, but it is one of the most resilient hair patterns. This is because your hair stays moisturized by natural oils spreading from your scalp to the ends of each hair strand. Be mindful of the balance between stripping your scalp of its natural oils and keeping it hydrated. There is such a thing as overwashing! Washing too regularly will rid your scalp of its naturally produced oils, and cause it to overproduce oils, resulting in oily hair. Use lightweight products like leave in conditioners and almond oil that will not weigh your hair down.",
      code: "1C"
    },
    {
      id: 3,
      title: "Wavy Swavy",
      image: "",
      answer: 3,
      label: "2A",
      description: "Your hair pattern is a lovely loose wave. These waves maintain your hair’s voluminous shape.Hydrating shampoos, conditioners, and water-based products like mousses can help lock in moisture, define your loose waves and tame frizz, without leaving your hair looking oily.",
      code: "2A"
    },
    {
      id: 4,
      title: "Wavy Curvy",
      image: "",
      answer: 4,
      label: "2B",
      description: "Your hair pattern embodies a lovely loose S-Shape or, the dreamy beach waves. Despite its beauty, you may battle frizz more often than not. Water-based products like creams and lotions can help lock in moisture and tame frizz without leaving your hair looking oily.",
      code: "2B"
    },
    {
      id: 5,
      title: "Wavy Whirly",
      image: "",
      answer: 5,
      label: "2C",
      description: "Your hair pattern embodies a lovely deep S-shape curl. Despite its beauty, you may battle frizz more often than not. Cream products and bimonthly hair masks can help lock in moisture and tame frizz, without leaving your hair looking oily.",
      code: "2C"
    },
    {
      id: 6,
      title: "Curly Twirly",
      image: "",
      answer: 6,
      label: "3A",
      description: "Your hair pattern is a beautiful loose spiral curl. Your spirals are likely large enough to fit around your finger. Moisture is essential for defining your curls and minimizing frizz. For this reason, you should shampoo your hair less frequently, about every 5-7 days. Use hair masks, deep conditioners, and leave in conditioners to lock in moisture and define your gorgeous curls.",
      code: "3A"
    },
    {
      id: 7,
      title: "Curly Spirally",
      image: "",
      answer: 7,
      label: "3B",
      description: "Your hair pattern is a beautiful spring-shaped curl. Your curls can likely wrap tightly around a pencil. Moisture is essential for defining your curls and minimizing frizz. For this reason, you should shampoo your hair less frequently, about every 7 days. Use deep conditioners, creams, butters, and oils to lock in moisture and define your gorgeous curls.",
      code: "3B"
    },
    {
      id: 8,
      title: "Curly Kinky",
      image: "",
      answer: 8,
      label: "3C",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a corkscrew or zigzag shape. Your coarsely textured hair is one of the most delicate curl patterns and should always be handled with care. Detangle with leave in conditioner and a wide-toothed comb for best results. Hydration is essential for defining your curls and minimizing frizz so use denser products like creams, butters, oils, and gels to lock in moisture and define your gorgeous curls.",
      code: "3C"
    },
    {
      id: 9,
      title: "Kinky Coily",
      image: "",
      answer: 9,
      label: "4A",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a coily corkscrew or zigzag that can wrap around a slim toothpick. Your coarsely textured hair is one of the most delicate curl patterns and should always be handled with care. Detangle with leave in conditioner and a wide-toothed comb for best results. Hydration is essential for defining your curls and minimizing frizz so use denser products like curl defining creams/custards, butters, oils, and gels to lock in moisture and define your gorgeous curls.",
      code: "4A"
    },
    {
      id: 10,
      title: "Kinky Crimpy",
      image: "",
      answer: 10,
      label: "4B",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a zigzag shape. Your coarsely textured hair is one of the most delicate curl patterns and should always be handled with care. Detangle with leave in conditioner and a wide-toothed comb for best results. Hydration is essential for defining your curls and minimizing frizz so use denser products like thick conditioners, defining creams/custards, oils, and gels to lock in moisture and define your gorgeous curls.",
      code: "4B"
    },
    {
      id: 11,
      title: "Kinky Ziggly",
      image: "",
      answer: 11,
      label: "4C",
      description: "Your hair pattern may contain multiple hair textures, but your beautiful curls likely resemble a zigzag shape. Your curls may be wound so tightly around themselves that you have to stretch them in order to see the shape. Your coarsely textured hair is one of the most delicate curl patterns and should always be handled with care. Detangle with leave in conditioner and a wide-toothed comb for best results. Hydration is essential for defining your curls and minimizing frizz so use denser products like thick conditioners, defining creams/custards, oils, and gels to lock in moisture and define your gorgeous curls.",
      code: "4C"
    },
  ];
  const hairLength = [
    {
      id: 1,
      title: "Short",
      image: "",
      answer: 1,
      description: "Your hair ranges from a cropped cut to chin length",
      code: "S"
    },
    {
      id: 2,
      title: "Medium",
      image: "",
      answer: 2,
      description: "Your hair falls from below the chin to the collarbone.",
      code: "M"
    },
    {
      id: 3,
      title: "Long",
      image: "",
      answer: 3,
      description: "Your hair hangs from below the collarbone and downwards",
      code: "L"
    },
  ];
  const hairDensity = [
    {
      id: 1,
      title: "Low",
      image: "",
      answer: 1,
      description: "With low density hair, you have a small amount of hair strands located in one measured portion of the scalp, allowing for the scalp to be easily visible through your hair. Low density hair works best with lighter serums, sprays and creams which help to prevent oily & lifeless hair.",
      code: "L"
    },
    {
      id: 2,
      title: "Medium",
      image: "",
      answer: 2,
      description: "With medium density hair, you have a good amount of hair strands located in one measured portion of the scalp, allowing for the scalp to be slightly visible through your hair. Medium density hair works best with many product types",
      code: "M"
    },
    {
      id: 3,
      title: "High",
      image: "",
      answer: 3,
      description: "With high density hair, you have a large amount of hair strands located in one measured portion of the scalp, meaning the scalp is not visible at all between hair strands. High Density hair works best with heavier gels and creams that can coat and hydrate each hair strand.",
      code: "H"
    },
  ];
  const hairPorosity = [
    {
      id: 1,
      title: "Low",
      image: "",
      answer: 1,
      description: "You have low porosity hair, meaning your hair strands have cuticles that are layered closely and tightly together. Moisture is not easily absorbed into low porosity hair strands, but using heat agents, like a steamer or warm towel, can help with product absorption. Heavy products should generally be avoided.Typically low porosity hair has not been chemically treated before (bleached, permed, relaxed).",
      code: "L"
    },
    {
      id: 2,
      title: "Medium",
      image: "",
      answer: 2,
      description: "You have medium porosity hair, meaning your hair strands have cuticles that are layered at a natural tightness level. Moisture is easily absorbed and retained within medium porosity hair strands, and it is considered the most hydrated of the porosity levels. Medium porosity hair holds styles (like curls formed using heat) well and takes to chemical treatments easily.",
      code: "M"
    },
    {
      id: 3,
      title: "High",
      image: "",
      answer: 3,
      description: "You have high porosity hair, meaning your hair strands have cuticles that are raised and layered loosely together. Moisture flows easily in and out of high porosity strands, causing dryness and tangles. Deep conditioners and other hydrating products help to retain moisture. High porosity hair is largely caused by heat damage and chemical treatments.",
      code: "H"
    },
  ];
  const hairProcessing = [
    {
      id: 1,
      title: "Perm/Relaxer",
      image: "",
      answer: 1,
      description: "As you know, perms/relaxers straighten, smooth, and tame your mane, but what else are they doing to your hair? Relaxers/perms work by penetrating the cuticle and cortex layers of your hair. Once these layers are broken, the chemicals in the relaxer/perm physically change the shape of your hair by breaking the natural chemical bonds (disulfide bonds) of your hair. When this happens, your beautiful, frizz free tresses are actually a lot weaker and more prone to breakage.To minimize damage and breakage, protect your scalp and ends with hydrating solutions like oils and hair masks.",
      code: "P"
    },
    {
      id: 2,
      title: "Dyed",
      image: "",
      answer: 2,
      description: "Pigments responsible for your natural hair color live under your cuticles. Ammonia, a chemical in hair dye breaks through your hair cuticles and allows the new pigments in your dye to take over. Your cuticle closes, locking the new color in. The strength of your hair is mostly due to disulfide bonds. Coloring your hair can break these bonds, making your hair weak, and easy to break. To keep your tresses thriving, don’t forget to condition and deep condition regularly!!",
      code: "D"
    },
    {
      id: 3,
      title: "Bleached",
      image: "",
      answer: 3,
      description: "Hair bleach lifts the cuticles on the hair strand to allow for hydrogen peroxide to enter into the hair follicle. The hydrogen peroxide then uses the process of oxidation to remove the melanin, or color, from your hair strand. Although bleaching may help to achieve the fabulous hair color you desire, it can also lead to dry and damaged hair that tangles often. In fact, the lifting of the cuticle on the hair strand is what leads directly to high porosity hair. Deep conditioners and other hydrating products help to retain moisture for bleached and high porosity hair.",
      code: "B"
    },
    {
      id: 4,
      title: "None",
      image: "",
      answer: 4,
      description: "",
      code: "N"
    },
  ];
  const { token } = useAuth();
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [recommendedpros, setRecommendedPros] = useState([]);
  const [recommendedStudy, setRecommendedStudy] = useState([]);
  const [recommendedcrafts, setRecommendedCrafts] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [myData,setMyData] = useState(JSON.parse(localStorage.getItem("userDatas")));
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const [loadingMorePros, setLoadingMorePros] = useState(false);
  const [loadingMoreCrafts, setLoadingMoreCrafts] = useState(false);
  const [loadingMoreStudies, setLoadingMoreStudies] = useState(false);
  const [quizData, setQuizData] = useState();

  const [selectedHairTextures,setSelectedHairTexture] = useState();
  const [selectedHairLength,setSelectedHairLength] = useState();
  const [selectedHairDensity,setSelectedHairDensity] = useState();
  const [selectedHairPorosity,setSelectedHairPorosity] = useState();
  const [selectedHairProcessing,setSelectedHairProcessing] = useState([]);
  const [
    showRecommendedLoadProductsButton,
    setShowRecommendedLoadProductsButton,
  ] = useState(false);
  const [
    showRecommendedLoadProsButton,
    setShowRecommendedLoadProsButton,
  ] = useState(false);
  const [
    showRecommendedLoadCraftsButton,
    setShowRecommendedLoadCraftsButton,
  ] = useState(false);
  const [
    showRecommendedLoadStudiesButton,
    setShowRecommendedLoadStudiesButton,
  ] = useState(false);
  const [loadingRecommendedCrafts, setLoadingRecommendedCrafts] = useState(
    false
  );
  const [loadingRecommendedProducts, setLoadingRecommendedProducts] = useState(
    false
  );
  const [loadingRecommendedPros, setLoadingRecommendedPros] = useState(false);
  const [loadingRecommendedStudies, setloadingRecommendedStudies] = useState(
    false
  );
  const tokenValue = JSON.parse(localStorage.getItem("userData"));
  const [activeIndex, setActiveIndex] = useState(-1);



  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const fetchProduct = async (
    route,
    type,
    updateState,
    updateLoading,
    displayLoadMore
  ) => {
    updateLoading(true);
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${route}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,

        }
      );
      if (responseData.results.length >= 10 ) displayLoadMore(true);
      updateState(responseData.results);
      updateLoading(false);
    } catch (err) {
      updateLoading(false);
    }
  };

  const $ = window.$
  useEffect(() => {
    window.scrollTo(0, 0);
    $(".show-more").click(function () {
      if($(".text").hasClass("show-more-height")) {
          $(this).text("Read Less");
      } else {
          $(this).text("Read More");
      }

      $(".text").toggleClass("show-more-height");
    });
  },[]);
  useEffect(() => {
    const getQuizDetails = async () => {
      try {
        const responseDetails= await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
            "GET",
            {
                "Content-Type": "application/json",
                Authorization: "Token " + tokenValue.token,
            }
        );
        localStorage.setItem(
          "userDatas",
          JSON.stringify({
            userFirstName: responseDetails.first_name,
            userLastName: responseDetails.last_name,
            userEmail: responseDetails.email,
            quiz: responseDetails.quiz_attempted,
            profile_pic: responseDetails.profile_pic
          })
        );
        setMyData(JSON.parse(localStorage.getItem("userDatas")));
      } catch (err) {

      }
        try {
            const responseDetails = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/quiz/`,
                "GET",
                {
                    "Content-Type": "application/json",
                    Authorization: "Token " + tokenValue.token,
                },
            );

            setQuizData(responseDetails)

            const hair_texture_val = hairTextures.filter((item) => { 
              if(item.label == responseDetails.hair_texture) 
              {
               return item;
              }
            });
            setSelectedHairTexture(hair_texture_val[0]);
            const hair_density_val = hairDensity.filter((item) => { 
              if(item.title == responseDetails.hair_density) 
              {
               return item;
              }
            });
            setSelectedHairDensity(hair_density_val[0]);
            const hair_length_val = hairLength.filter((item) => { 
              if(item.title == responseDetails.hair_length) 
              {
               return item;
              }
            });
            setSelectedHairLength(hair_length_val[0]);
            const hair_Porosity_val = hairPorosity.filter((item) => { 
              if(item.title == responseDetails.hair_porosity) 
              {
               return item;
              }
            });
            setSelectedHairPorosity(hair_Porosity_val[0]);
            const hair_processing_val = hairProcessing.filter(item =>  
              responseDetails.hair_processing_history.includes(item.title));
           
           setSelectedHairProcessing(hair_processing_val);
        } catch (err) {
    
        }
    };
    getQuizDetails();
  }, []);
  useEffect(() => {

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let type;
    let urltoRecommendedCrafts = `/product/?is_craft=True&recommended=True&search=${searchquery}`;
    let urltoRecommendedProducts = `/product/?is_product=True&recommended=True&search=${searchquery}`;
    let urltoRecommendedStudy = `/product/?is_study=True&recommended=True&search=${searchquery}`;
    let urltoRecommendedPros = `/product/?is_pros=True&recommended=True&search=${searchquery}`;
    type = "pr";
    if (token && myData.quiz)
      fetchProduct(
        urltoRecommendedProducts,
        type,
        setRecommendedProducts,
        setLoadingRecommendedProducts,
        setShowRecommendedLoadProductsButton
      );
    type = "c";
    if (token && myData.quiz)
      fetchProduct(
        urltoRecommendedCrafts,
        type,
        setRecommendedCrafts,
        setLoadingRecommendedCrafts,
        setShowRecommendedLoadCraftsButton
      );
    type = "p";
    if (token && myData.quiz)
      fetchProduct(
        urltoRecommendedPros,
        type,
        setRecommendedPros,
        setLoadingRecommendedPros,
        setShowRecommendedLoadProsButton
      );
    type = "s";
    if (token && myData.quiz)
      fetchProduct(
        urltoRecommendedStudy,
        type,
        setRecommendedStudy,
        setloadingRecommendedStudies,
        setShowRecommendedLoadStudiesButton
      );
  }, [token, searchText, myData.quiz]);



  const handleLoadMoreRecommendedProducts = async () => {
    setLoadingMoreProducts(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_product=True&recommended=True&search=${searchquery}&limit=${recommendedProducts.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,

        }
      );
     
      setRecommendedProducts(responseData.results);
      setLoadingMoreProducts(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoadProductsButton(true);
      } else {
        setShowRecommendedLoadProductsButton(false);
      }
    } catch (err) {
      setLoadingMoreProducts(false);
    }
  };
  const handleLoadMoreRecommendedPros = async () => {
    setLoadingMorePros(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_craft=True&recommended=True&search=${searchquery}&limit=${recommendedpros.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,

        }
      );
     ;
      setRecommendedPros(responseData.results);
      setLoadingMorePros(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoadProsButton(true);
      } else {
        setShowRecommendedLoadProsButton(false);
      }
    } catch (err) {
      setLoadingMorePros(false);
    }
  
  };
  const handleLoadMoreRecommendedCrafts = async () => {
    setLoadingMoreCrafts(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_craft=True&recommended=True&search=${searchquery}&limit=${recommendedcrafts.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token,

        }
      );
      
      setRecommendedCrafts(responseData.results);
      setLoadingMoreCrafts(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoadCraftsButton(true);
      } else {
        setShowRecommendedLoadCraftsButton(false);
      }
    } catch (err) {
      setLoadingMoreCrafts(false);
    }
 
  };
  const handleLoadMoreRecommendedStudies = async () => {
    setLoadingMoreStudies(true);

    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoEditandAdd = `/product/?is_study=True&recommended=True&search=${searchquery}&limit=${recommendedStudy.length + 10}`;

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoEditandAdd}`,
        "GET",
        {
          "Content-Type": "application/json",
          Authorization: "Token " + token

        }
      );
      
      setRecommendedStudy(responseData.results);
      setLoadingMoreStudies(false);
      if (responseData.results.length >= 10) {
        setShowRecommendedLoadStudiesButton(true);
      } else {
        setShowRecommendedLoadStudiesButton(false);
      }
    } catch (err) {
      setLoadingMoreStudies(false);
    }
   
  };
  const addToFavourite = async (val, item, id) => {
    setActiveIndex(id);

    let type;
    let urltoWishlist = `/wishlist/`;
    let searchquery = searchText;
    if (props.location.state) searchquery = props.location.state.search;
    let urltoRecommendedCrafts = `/product/?is_craft=True&recommended=True&search=${searchquery}`;
    let urltoRecommendedProducts = `/product/?is_product=True&recommended=True&search=${searchquery}`;
    let urltoRecommendedStudy = `/product/?is_study=True&recommended=True&search=${searchquery}`;
    let urltoRecommendedPros = `/product/?is_pros=True&recommended=True&search=${searchquery}`;
    let formData = new FormData();
    formData.append("id", val);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}${urltoWishlist}`,
        "POST",
        {
          Authorization: "Token " + tokenValue.token,
        },
        formData
      );
      type = item;

    if (responseData && type == "isProduct")
      fetchProduct(
        urltoRecommendedProducts,
        type,
        setRecommendedProducts,
        setLoadingRecommendedProducts,
        setShowRecommendedLoadProductsButton
      );
    if (responseData && type == "isCraft")
      fetchProduct(
        urltoRecommendedCrafts,
        type,
        setRecommendedCrafts,
        setLoadingRecommendedCrafts,
        setShowRecommendedLoadCraftsButton
      );
    if (responseData && type == "isPros")
      fetchProduct(
        urltoRecommendedPros,
        type,
        setRecommendedPros,
        setLoadingRecommendedPros,
        setShowRecommendedLoadProsButton
      );
    if (responseData && type == "isStudy")
      fetchProduct(
        urltoRecommendedStudy,
        type,
        setRecommendedStudy,
        setloadingRecommendedStudies,
        setShowRecommendedLoadStudiesButton
      );
      setActiveIndex(-1);

    } catch (err) {
      setActiveIndex(-1)
    }
  };
  return (

    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Results</title>
      </Helmet>
      <div className={[`subpage-headcover-main`,classess.headerCoverMainDiv].join(" ")}>
        <div
          style={{
            backgroundImage: `-webkit-linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
             backgroundImage: `linear-gradient(0deg, #0000001f, rgb(0 0 0 / 70%)), url(${image})`,
            }}
          className={[`subpage-headcover-wrap`, classess.headerCoverDiv].join(" ")}
        >
          <div
            className={[
              `container`,
              `d-flex`,
              `flex-column`,
              `justify-content-center`,
              `align-items-center`,
              `subpage-headcover`,
              classess.headerCoverHeaderDiv,
            ].join(" ")}
            style={{ minHeight: "65vh" }}
          >
            <h1
              className={[`noMarginBottom`, classess.headerCoverHeading].join(
                " "
              )}
            >
              Hair Profile Overview
            </h1>
            
            <div className="d-flex justify-content-center craft-head">
                <div
                className={[
                  `d-flex`,
                  `mt-5`,
                  `mb-5`,
                  `d-flex`,
                  `subpage-search`,
                  classes.headerCoverSearchDiv,
                ].join(" ")}
              >
                <input
                  type="text"
                  className={classes.headerCoverSearchInputField}
                  placeholder="What are you looking for?"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <img
                  className={classes.headerCoverSearchButton}
                  alt="search"
                  src="assets/img/relevant/products1/products/search.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center" style={{ color: "#8E5051" }}>
          TRESS Hair Results - {quizData?.hair_profile}
        </h2>
        <h3 className="text-center mt-4" style={{ color: "#732424" }}>
          Understanding your Hair Profile
        </h3>

        <div className="text-center mt-4 mb-4">

              <div id="text-show-less">
                <div className="text show-more-height hair-p-o-content  ">
                {/* <p>Hair Code - {quizData?.hair_profile}</p> */}
                <p style={{ color: "#732424" }}>Hair Texture - Type {selectedHairTextures?.label}</p>
                <span>{selectedHairTextures?.description}</span>
                <p style={{ color: "#732424" }}>Hair Length - {selectedHairLength?.title}</p>
                <span>{selectedHairLength?.description}</span>
                <p style={{ color: "#732424" }}>Hair Density - {selectedHairDensity?.title}</p>
                <span>{selectedHairDensity?.description}</span>
                <p style={{ color: "#732424" }}>Hair Porosity - {selectedHairPorosity?.title}</p>
                <span>{selectedHairPorosity?.description}</span>
                <p style={{ color: "#732424" }}>History of Processing</p>
                { selectedHairProcessing.length ? (
                  selectedHairProcessing.map((items, i) =>{
                    return (
                      <div>
                      <p style={{ color: "#732424" }} >{items?.title}</p>
                      <span>{items?.description}</span>
                      </div>
                    )
                  })
                  
                ): null
                }
                </div>
                <div className="show-more mt-4">Read More</div>
              </div>

        </div>

        <div>
          {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Products Recommended To You
              </h4>
              {loadingRecommendedProducts && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                               
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                  <img alt="GIF" src={Gif1} />
                                  
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedProducts && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedProducts.length ? (
                        recommendedProducts.map((product, i) => {
                          return (
                            <a
                              className="col-12 col-md-6 mb-3"
                              
                              key={i}
                            >
                              <div className="row mb-4">
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{
                                    height: "150px",
                                  }}
                                >
                                  <div
                                    className={classes.theProductImage}
                                    style={{
                                      backgroundImage: `url(${product.image_external_url === null || product.image_external_url === "" ? product.image : product.image_external_url})`,
                                      width: "100%",
                                      height: "80%",
                                      backgroundPosition: "center",
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                  <div className="make-wish">
                                    <a  className={product.is_wishlisted ? "active" : "" + i == activeIndex ? "active" : ""} onClick={()=> addToFavourite(product.id, "isProduct", i)}><img src={wishlist} className="img-fluid" alt="" /></a>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                  <a target="_blank" rel="noopener noreferrer"
                                     href={product.link_1}>
                                  <div className="d-flex flex-column">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classes.theProductHeading,
                                      ].join(" ")}
                                    >
                                      {product.name.length > 40
                                        ? `${product.name.substr(0, 51)}...`
                                        : product.name}
                                    </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {product.hair_length.length > 2 ? `${product.hair_length.join(', ').substr(0, 13)}...`
                                     : product.hair_length.join(', ')}
                                      </p>

                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Processing : {product.hair_processing_history.length > 2 ? `${product.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : product.hair_processing_history.join(', ')}
                                      </p>
                                    </div>
                                    
                                  </div>
                                  </a>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5 className="col">
                          {searchText === "" ?   "No Products recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"} 
                          </h5>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {loadingMoreProducts ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showRecommendedLoadProductsButton && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMoreRecommendedProducts}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
          {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Crafts Recommended To You
              </h4>
              {loadingRecommendedCrafts && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                                
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                  <img alt="GIF" src={Gif1} />
                               
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedCrafts && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedcrafts.length ? (
                        recommendedcrafts.map((craft, i) => {
                          return (
                            <a
                              className="col-12 col-md-6 mb-3"
                              
                              key={i}
                            >
                              <div className="row mb-4" key={i}>
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{ height: "150px" }}
                                >
                                  <div
                                    className={classess.thecraftImage}
                                    style={{
                                      backgroundImage: `url(${craft.image_external_url === null || craft.image_external_url === "" ? craft.image : craft.image_external_url})`,
                                      width: "100%",
                                      height: "100%",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                      boxShadow: "0px 0px 1px 2px #a7a7a7",
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                  <div className="make-wish">
                                    <a  className={craft.is_wishlisted ? "active" : "" + i == activeIndex ? "active" : ""} onClick={()=> addToFavourite(craft.id, "isCraft", i)}><img src={wishlist} className="img-fluid" alt="" /></a>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                  <a target="_blank" rel="noopener noreferrer"
                                     href={craft.link_1}>
                                  <div className="d-flex flex-column">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classess.thecraftHeading,
                                      ].join(" ")}
                                    >
                                       {craft.name.length > 40
                                        ? `${craft.name.substr(0, 51)}...`
                                        : craft.name}
                                    </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {craft.hair_length.length > 2 ? `${craft.hair_length.join(', ').substr(0, 13)}...`
                                        : craft.hair_length.join(', ')}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Density : {craft.hair_density.length > 2 ? `${craft.hair_density.join(', ').substr(0, 12)}...`
                                        : craft.hair_density.join(', ')}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Processing : {craft.hair_processing_history.length > 2 ? `${craft.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : craft.hair_processing_history.join(', ')}
                                      </p>
                                    </div>
                                  </div>
                                  </a>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5 className="">
                          {searchText === "" ?   "No Crafts recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"}
                          </h5>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {loadingMoreCrafts ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showRecommendedLoadCraftsButton && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMoreRecommendedCrafts}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
          {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Pros Recommended To You
              </h4>
              {loadingRecommendedPros && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                                
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                 
                                  <img alt="GIF" src={Gif1} />

                                 
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedPros && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedpros.length ? (
                        recommendedpros.map((pros, i) => {
                          return (
                            <a
                              key={i}
                              className="col-12 col-md-6 mb-3"
                              
                            >
                              <div className="row mb-4">
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{
                                   
                                    borderRadius: "5px",
                                    height: "150px",
                                  }}
                                >
                                  <div
                                    className={[`pro-image-wrap`,classess.theprosImage].join(" ")}
                                    style={{
                                      backgroundImage: `url(${pros.image_external_url === null || pros.image_external_url === "" ? pros.image : pros.image_external_url})`,
                                      height: "100%",
                                      width: "100%",
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  ></div>
                                   <div className="make-wish">
                                    <a  className={pros.is_wishlisted ? "active" : "" + i == activeIndex ? "active" : ""} onClick={()=> addToFavourite(pros.id, "isPros", i)}><img src={wishlist} className="img-fluid" alt="" /></a>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 pt-3 pt-md-0 ">
                                  <a target="_blank" rel="noopener noreferrer"
                                     href={pros.link_1}>
                                  <div className="d-flex flex-column justify-content-between">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classess.theProsHeading,
                                      ].join(" ")}
                                    >
                                      {pros.name.length > 40
                                        ? `${pros.name.substr(0, 51)}...`
                                        : pros.name}                                   
                                         </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {pros.hair_length.length > 2 ? `${pros.hair_length.join(', ').substr(0, 13)}...`
                                       : pros.hair_length.join(', ')}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Density : {pros.hair_density.length > 2 ? `${pros.hair_density.join(', ').substr(0, 12)}...`
                                       : pros.hair_density.join(', ')}
                                      </p>
                                    </div>
                                  </div>
                                  </a>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5>{searchText === "" ?   "No Pros recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"}</h5>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {loadingMorePros ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showRecommendedLoadProsButton && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMoreRecommendedPros}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
          {token ? (
            <div className="d-flex flex-column mt-5 mb-5">
              <h4 style={{ color: "#8E5051", fontWeight: 600 }}>
                Studies Recommended To You
              </h4>
              {loadingRecommendedStudies && (
                <div>
                  <div className="row col-12 mb-5 mt-5">
                    {[...Array(3)].map((study, i) => {
                      return (
                        <span
                          className="col-12 col-md-6 mb-3"
                          target="_blank" rel="noopener noreferrer"
                          key={i}
                        >
                          <div className="row mb-4">
                            <div
                              className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                              style={{ height: "150px" }}
                            >
                              <div
                                className={classes.theprosImage}
                                style={{
                                  backgroundColor: "rgb(136,136,136,0.4)",
                                  width: "100%",
                                  height: "100%",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                              <div className="d-flex flex-column">
                              
                                <img alt="GIF" src={Gif2} />
                                <div className={`pt-2`}>
                                 
                                  <img alt="GIF" src={Gif1} />

                                 
                                  <img alt="GIF" src={Gif1} className="mt-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {!loadingRecommendedStudies && (
                <div className="mt-3">
                  {!myData.quiz && (
                    <h5 className="mt-3">
                      You don't have a hair profile, kindly submit a
                      <Link
                        to="/questionaire"
                        style={{ color: "rgb(114, 37, 38)", fontWeight: 600 }}
                      >
                        &nbsp;QUIZ&nbsp;
                      </Link>
                      , to your your recommended studies
                    </h5>
                  )}
                  {myData.quiz && (
                    <div className="row col-12 mb-5 mt-5">
                      {recommendedStudy.length ? (
                        recommendedStudy.map((study, i) => {
                          return (
                            <a
                              className="col-12 col-md-6 mb-3"
                              key={i}
                            >
                              <div className="row mb-4">
                                <div
                                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                  style={{ height: "150px" }}
                                >
                                  <div
                                    className={classess.theStudyImage}
                                    style={{
                                      backgroundImage: `url(${study.image_external_url === null || study.image_external_url === "" ? study.image : study.image_external_url})`,
                                      width: "100%",
                                      height: "100%",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                  <div className="make-wish">
                                    <a  className={study.is_wishlisted ? "active" : "" + i == activeIndex ? "active" : ""} onClick={()=> addToFavourite(study.id, "isStudy", i)}><img src={wishlist} className="img-fluid" alt="" /></a>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                  <a target="_blank" rel="noopener noreferrer"
                                    href={study.link_1}>
                                  <div className="d-flex flex-column">
                                    <h2
                                      className={[
                                        `noMarginBottom`,
                                        classess.theStudyHeading,
                                      ].join(" ")}
                                    >
                                      {study.name.length > 40
                                        ? `${study.name.substr(0, 51)}...`
                                        : study.name}
                                    </h2>
                                    <div className={`pt-2`}>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Length : {study.hair_length.length > 2 ? `${study.hair_length.join(', ').substr(0, 13)}...`
                                       : study.hair_length.join(', ')}
                                      </p>

                                      <p
                                        style={{
                                          fontSize: "16px",
                                          color: "#888",
                                        }}
                                      >
                                        Hair Processing : {study.hair_processing_history.length > 2 ? `${study.hair_processing_history.join(', ').substr(0, 12)}...`
                                       : study.hair_processing_history.join(', ')}
                                      </p>
                                    </div>
                                   
                                  </div>
                                  </a>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-between">
                          <h5>{searchText === "" ?   "No Studies recommended for your hair profile" : "Sorry, we couldn’t find a match for your search"}</h5>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {loadingMoreStudies ? (
            <LoadingSpinner />
          ) : (
            <div>
              {showRecommendedLoadStudiesButton && (
                <div>
                  <button
                    className={classes.loadMoreButton}
                    onClick={handleLoadMoreRecommendedStudies}
                  >
                    Explore More
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendedResults;
