import classes from "./ThirdQuestion.module.css";
import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import LowDensity from "./LowDensity.png";
import MediumDensity from "./MediumDensity.png";
import HighDensity from "./HighDensity.png";
const ThirdQuestion = (props) => {

  const [selectStyle, setselectStyle] = useState(props.data);
  const hairStyles = [
    {
      id: 1,
      title: "Small",
      image: LowDensity,
      answer: 1,
      description: "With low density hair, you have a small amount of hair strands located in one measured portion of the scalp, allowing for the scalp to be easily visible through your hair."
    },
    {
      id: 2,
      title: "Medium",
      image: MediumDensity,
      answer: 2,
      description: "With medium density hair, you have a good amount of hair strands located in one measured portion of the scalp, allowing for the scalp to be slightly visible through your hair."
    },
    {
      id: 3,
      title: "Large",
      image: HighDensity,
      answer: 3,
      description: "With high density hair, you have a large amount of hair strands located in one measured portion of the scalp, meaning the scalp is not visible at all between hair strands."
    },
  ];

  const $ = window.$
  useEffect(() => {  
    window.scrollTo(0, 0)
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  },[]);

  const handleSelectedStyle = (selectStyle) => {
    props.handleThirdAnswer(selectStyle.answer);
    setselectStyle(selectStyle.id);
  };
  const handleNextButton = () => {
    if (!selectStyle) {
      Swal.fire({
        title: "Error",
        text: "Please select density level",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      props.next();
    }
  };
  const handleBackButton = () => {
    props.back();
  };
  return (
    <div className={[`question-in-main`,classes.secondQuestionMainDiv].join(" ")}>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 progress-main">
          <div className="progress brwn-prgrs">
            <div className="progress-bar progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "50%"}}><span>50%</span></div>
          </div>
        </div>
      </div>

      <div className={[`pt-5`, `pb-4`, classes.questionContainer].join(" ")}>
        <h2
          className={[`text-center`, `noMarginBottom`, classes.question].join(
            " "
          )}
        >
          3. WHAT IS YOUR HAIR DENSITY LEVEL?
          <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="right" title="Hair density is the amount of hair strands located in one measured area of the scalp. This is not to be confused with thickness, which is the width of a single hair strand. Hair can be classified as low, medium or high density.">i</button>
        </h2>
        
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ transform: "translateY(-15px)" }}
        >
          <div className="col-11 col-md-11 col-lg-10 m-auto">
          <div className="row justify-content-center mt-5">
            {hairStyles.map((style, i) => {
              return (
                <div
                  className={[
                    `col-6`,
                    `col-md-4`,
                    `d-flex`,
                    `justify-content-center`,
                    `flex-column`,
                    `align-items-center`,
                    `my-4`,
                    classes.hairStyleDiv,
                  ].join(" ")}
                  key={i}
                  onClick={() => handleSelectedStyle(style)}
                >
                  <div className={classes.styleWrapperDiv}>
                    <div className="d-flex justify-content-center">
                      {style.id === selectStyle && (
                        <div
                          className={[
                            `d-flex`,
                            `justify-content-center`,
                            `align-items-center`,
                            classes.selectOverlay,
                          ].join(" ")}
                        >
                          <i className="fas fa-check"></i>
                        </div>
                      )}
                      <img
                        alt={style.title}
                        src={style.image}
                        className={classes.hairImage}
                      />
                      <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="top" title={style.description}>i</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center q-nav-btn">
        <label
            onClick={handleBackButton}
            className={[`back-btn`, classes.nextButton].join(" ")}
          >
            BACK
          </label>
          <label
            onClick={handleNextButton}
            className={[`nxt-btn`, classes.nextButton].join(" ")}
          >
            NEXT
          </label>
      </div>
    </div>
  );
};

export default ThirdQuestion;
