import classes from "./FifthQuestion.module.css";
import React, { useState, useEffect} from "react";
import LoadingSpinner from "../../shared/UI/LoadingSpinner";
import Swal from "sweetalert2";
import Bleached from "./Bleached.png";
import Dyed from "./Dyed.png";
import PermRelaxer from "./Perm-Relaxer.png";
import None from "./None.png";
const FifthQuestion = (props) => {

  const [selectStyle, setselectStyle] = useState();
  const hairStyles = [
    {
      id: 1,
      title: "Perm/Relaxer",
      image: PermRelaxer,
      answer: 1,
      description: "Your hair has a relaxer or perm, using chemicals to penetrate the cuticle and cortex layers of your hair to chemically straighten or add curls to your mane."
    },
    {
      id: 2,
      title: "Dyed",
      image: Dyed,
      answer: 2,
      description: "You have hair dyed without using bleach and have most likely used ammonia, a chemical in hair dye that breaks through your hair cuticles and allows the new pigments in your dye to take over. Your cuticle closes, locking the new color in."
    },
    {
      id: 3,
      title: "Bleached",
      image: Bleached,
      answer: 3,
      description: "You have hair dyed with bleach, a chemical that lifts the cuticles on the hair strand to allow for hydrogen peroxide to enter into the hair follicle. The hydrogen peroxide then uses the process of oxidation to remove the melanin, or color, from your hair strand."
    },
    {
      id: 4,
      title: "None",
      image: None,
      answer: 4,
      description: "Your hair has no chemical processing."
    },
  ];
  const [processingHistory, setProcessingHistory] = useState(props.data || []);

  const $ = window.$
  useEffect(() => {
    window.scrollTo(0, 0)

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  },[]);

  const handleSelectedStyle = (selectStyle) => {
    setselectStyle(selectStyle.id);
    if(selectStyle.answer != 4){
      const indexValue = processingHistory.indexOf(4);
      if (indexValue > -1) {
        processingHistory.splice(indexValue, 1);
      }
      if(!processingHistory.includes(selectStyle.answer)){
      setProcessingHistory([...processingHistory, selectStyle.answer]);
      props.handleFifthAnswer(processingHistory)
      }
      else{
        const indexVal = processingHistory.indexOf(selectStyle.answer);
        if (indexVal > -1) {
          processingHistory.splice(indexVal, 1);
        }
      }
      
      props.handleFifthAnswer(processingHistory)

    }
    if(selectStyle.answer === 4){
      if(!processingHistory.includes(selectStyle.answer)){
        setProcessingHistory([4]);
        props.handleFifthAnswer(processingHistory)
      }
      else{
        const indexHistoryVal = processingHistory.indexOf(selectStyle.answer);
        if (indexHistoryVal > -1) {
          processingHistory.splice(indexHistoryVal, 1);
        }
      }
    }    
    props.handleFifthAnswer(processingHistory)
  };

  const handleNextButton = () => {
    props.handleFifthAnswer(processingHistory)
    if (processingHistory.length === 0) {
      Swal.fire({
        text: "Please select processing",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      props.next();
    }
  };

  const handleBackButton = () => {
    props.handleFifthAnswer(processingHistory)
    props.back();
  };

  const load = <LoadingSpinner />;
  return (
    <div className={[`question-in-main`,classes.firstQuestionMainDiv].join(" ")}>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 progress-main">
          <div className="progress brwn-prgrs">
            <div className="progress-bar progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "83.33%"}}><span>83%</span></div>
          </div>
        </div>
      </div>

      <div className={[`pt-5`, `pb-4`, classes.questionContainer].join(" ")}>
        <h2
          className={[`text-center`, `noMarginBottom`, classes.question].join(
            " "
          )}
        >
          5. WHAT IS YOUR HISTORY OF PROCESSING?
        </h2>
       
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ transform: "translateY(-15px)" }}
        >
          <div className="col-12 col-md-11 col-lg-10 m-auto">
          <div className="row justify-content-center mt-5">
            {hairStyles.map((style, i) => {
              return (
                <div
                  className={[
                    `col-6`,
                    `col-lg-3`,
                    `d-flex`,
                    `justify-content-center`,
                    `flex-column`,
                    `align-items-center`,
                    `mt-4`,
                    classes.hairStyleDiv,
                  ].join(" ")}
                  key={i}
                  onClick={() => handleSelectedStyle(style)}
                >
                  <div className={classes.styleWrapperDiv}>
                    <div className="d-flex justify-content-center">
                      { processingHistory.includes(style.answer) && 
                        <div
                          className={[
                            `d-flex`,
                            `justify-content-center`,
                            `align-items-center`,
                            classes.selectOverlay,
                          ].join(" ")}
                        >
                          <i className="fas fa-check"></i>
                        </div>
                      }
                      <img
                        style={{ maxWidth: "100%" }}
                        alt={style.title}
                        src={style.image}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <label
                        className={[`pt-3`, classes.optionTitle].join(" ")}
                      >
                        {style.title}
                      </label>
                      <button type="button" className="btn btn-secondary tltp-btn ml-2" data-toggle="tooltip" data-placement="top" title={style.description}>i</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center q-nav-btn">
        {!props.loading ? (
          <span>
            <label
            onClick={handleBackButton}
            className={[`back-btn`, classes.nextButton].join(" ")}
          >
            BACK
          </label>,
            <label
              onClick={handleNextButton}
              className={[`nxt-btn`, classes.nextButton].join(" ")}
            >
              NEXT
            </label>
          </span>
        ) : (
          <div className="d-flex justify-content-center">{load}</div>
        )}
      </div>
    </div>
  );
};

export default FifthQuestion;
