import React, { Component } from "react";
import { Link } from "react-router-dom";

class PageNotFound extends Component {
  render() {
    return (
      <div className="not-found-page">
        <h1 className="title1">404</h1>
        <h5 className="title5 mt-1 mb-5">Page Not Found</h5>
        <Link to="/" class="radius-btn">BACK HOME</Link>
      </div>
    );
  }
}

export default PageNotFound;
