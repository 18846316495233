import React, { Component } from "react";
import { Link } from "react-router-dom";

class Error500 extends Component {
  render() {
    return (
      <div className="not-found-page">
        <h1 className="title1">500</h1>
        <h5 className="title5 mt-1 mb-5">Internal Server Error</h5>
        <Link to="/" class="radius-btn">BACK HOME</Link>
      </div>
    );
  }
}

export default Error500;
