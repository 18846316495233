import classes from "./Login.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, {
  useCallback,
  useReducer,
  useState,
} from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useHttpClient } from "../shared/hooks/http-hook";
import { useHistory } from "react-router-dom";
import Input from "../shared/UI/Input";
import LoadingSpinner from "../shared/UI/LoadingSpinner";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value,
    };
    const updateValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid,
    };
    let updatedFormIsValid = true;
    for (const key in updateValidities) {
      updatedFormIsValid = updatedFormIsValid && updateValidities[key];
    }
    return {
      formIsValid: updatedFormIsValid,
      inputValidities: updateValidities,
      inputValues: updatedValues,
    };
  }
  return state;
};
const ResetPassword = () => {
 
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [formStep, setFormStep] = useState(1);
  const history = useHistory();
  const { isLoading, sendRequest } = useHttpClient();
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      email: "",
    },
    inputValidities: {
      email: false,
    },
    formIsValid: false,
  });
  
  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 1) {
      if (email.length <= 0) {
        Swal.fire({
          title: "Error",
          text: "Please enter email",
          icon: "error",
          confirmButtonText: "OK",
        });
      } 
      else if((email.length > 75 )){
        Swal.fire({
            title: "Error",
            text: "Enter valid details. Maximum length: 75 ",
            icon: "error",
            confirmButtonText: "OK",
        });
      }
      else if(formState.formIsValid == false){
        Swal.fire({
          text: "Please fill valid email",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#722526",
        });
      }
      else {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/auth/password/reset/`,
            "POST",
            {
              "Content-Type": "application/json",
            },
            JSON.stringify({
              email: email,
            })
          );    
  
          Swal.fire({
            title: "Sent",
            text: responseData.detail,
            icon: "success",
            confirmButtonText: "Ok",
            allowEscapeKey:false,
            allowOutsideClick: true
          }).then((result) => {
            if (result.isConfirmed) {
              setSubmitted(false);
              setEmail(null);
              history.push("/");
              history.go("/");
            }
            if (result.dismiss === Swal.DismissReason.backdrop) {
              history.push("/");
              history.go("/");       
            }
         })
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: "we cannot find an account with that email address",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    } 
   
  };
  const load = <LoadingSpinner />;
  return (
    <div
      className={[
        `d-flex`,
        `justify-content-center`,
        `align-items-center`,
        classes.loginFormMainContainer,
      ].join(" ")}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 m-auto">
          <div className="signup-in">
          <div
        className={[
          
          `col-12 col-md-12`,
          `signup-wrap`,
          classes.loginFormContainer,
        ].join(" ")}
      >
        <div>
          <div className="d-flex flex-column align-items-center">
            <h1 className={[`noMarginBottom`, `text-center`].join(" ")}>
              Reset Password
            </h1>
            <div
              className="mb-3 mt-2"
              style={{
                width: "50px",
                height: "8px",
                backgroundColor: "#8E5051",
              }}
            ></div>
          </div>
          <div
            className={[
              `d-flex`,
              `justify-content-center`,
              classes.formSuggestionLabelDiv,
            ].join(" ")}
          >
            <label className={[`noMarginBottom`, `text-center`].join(" ")}>
              {"Remember Password ? "}
              <Link to="/login">
                <span
                  style={{
                    textDecoration: "underline",
                    color: "rgb(142, 80, 81)",
                    cursor: "pointer",
                  }}
                >
                  Log in here
                </span>
              </Link>
            </label>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            {formStep === 1 ? (
              <div className={[`d-flex`, `flex-column`].join(" ")}>
                <label className={[`flex-column`].join(" ")}>Email *</label>
                <Input
                  id="email"
                  type="text"
                  placeholder="Enter Email"
                  onInputChange={inputChangeHandler}
                  email
                  required
                  errorText="Please enter a valid email!"
                  setField={setEmail}
                />
              </div>
            ) : null}
           
            <div className={[`d-flex`, `flex-column`, `mt-4`].join(" ")}>
              {!isLoading ? (
                <button className={[`c-btn-primary`, classes.loginButton].join(" ")} type="submit">
                  {formStep === 1 ? "Get Link" : "Submit"}
                </button>
              ) : (
                <div className="d-flex justify-content-center">{load}</div>
              )}
            </div>
          
          </form>
        </div>
      </div>
          </div>
        </div>

      </div>
      </div>
    </div>
  );
};

export default ResetPassword;
